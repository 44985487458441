import React, {useState, useEffect} from 'react';
import axios from "axios";
import {apiConfig} from "../../../services/apiConfig";
import useAuth from "../../../auth/useAuth";
import { Form, Row, Col, Button, ListGroup, FloatingLabel } from 'react-bootstrap';

const Update = (props) => {
	const auth = useAuth();
    const user = JSON.parse(localStorage.getItem('user'));
	const [rolList,setRolList] = useState([]);
    const [datoSeleccionado,setDatoSeleccionado] = useState([]);




	function updateUser(event){ 
           
		event.preventDefault();
		axios.post(apiConfig+`profile/update/update.php`,datoSeleccionado)
		   .then((res) => {
			  let {data} = res;                  
			  if(data.success && data.message){ 			  }
				props.getData(datoSeleccionado)
		   })
		  .catch(function (res) {
			  
		});
		
		
  }
  const handleChange = (e) => {        
	setDatoSeleccionado({...datoSeleccionado,[e.target.name]:e.target.value});		
}
useEffect(() => {
	setDatoSeleccionado(props.data)
	axios.get(apiConfig+'profile/rol/listRol.php')
	.then((getData) => {
		setRolList(getData.data); 
					   
	});	 
 }, []);


	return  <div className="text-start">
	  <div className="col-12 pt-1">
		<Form onSubmit={updateUser}>
		  <Row>
			<Col sm={6} className="mb-2">
			  <FloatingLabel label="Nombre">
				<Form.Control
				  name="name"
				  type="text"
				  value={datoSeleccionado && datoSeleccionado.name}
				  onChange={handleChange}
				  pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
				  maxLength={20}
				/>
			  </FloatingLabel>
			</Col>
			<Col sm={6} className="mb-2">
			  <FloatingLabel label="Apellido">
				<Form.Control
				  name="last_name"
				  type="text"
				  value={datoSeleccionado && datoSeleccionado.last_name}
				  onChange={handleChange}
				  pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
				  maxLength={20}
				/>
			  </FloatingLabel>
			</Col>
		  </Row>
		  <Form.Group className="mb-2">
			<FloatingLabel label="Teléfono">
			  <Form.Control
				name="phone"
				type="text"
				value={datoSeleccionado && datoSeleccionado.phone}
				onChange={handleChange}
				minLength={10}
				maxLength={10}
				pattern="[0-9]+$"
			  />
			</FloatingLabel>
		  </Form.Group>
		  <Form.Group className="mb-2">
			<FloatingLabel label="Correo electrónico">
			  <Form.Control
				name="email"
				type="email"
				value={datoSeleccionado && datoSeleccionado.email}
				onChange={handleChange}
			  />
			</FloatingLabel>
			</Form.Group>
		  <Form.Group className="mb-2">
			<FloatingLabel controlId="floatingSelect" label="Rol asignado">
      <Form.Select aria-label="Rol asignado"
		
				as="select"
				name="id_user_rol"				
				onChange={handleChange}
				required
			  >
				{rolList &&
				  rolList
					.filter((x) => Number(x.id_user_rol) === Number(datoSeleccionado.id_user_rol))
					.map((z, i) => (
					  <option key={i}>{z.nombre}</option>
					))}
				{rolList &&
				  rolList.map((a, i) => {
					return (
					  <option key={i} value={a.id_user_rol}>
						{a.nombre}
					  </option>
					);
				  })}
			  	</Form.Select>
    		</FloatingLabel>
		  </Form.Group>


	
	
		  <br />
		  <div className="mb-4">
			<Button type="submit" className="btn app-button shadow">
			  Guardar
			</Button>
		  </div>
		</Form>
	  </div>
	</div>;
	
};

export default Update;
