import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import * as BsIcons from "react-icons/bs";
import { ListGroup } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

import moment from "moment";
import { NavLink, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import Update from "./Update/Update";
import Imagen from "./Imagen";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TabContent from 'react-bootstrap/TabContent'
import axios from "axios";
import {apiConfig} from "../../services/apiConfig";
import { useData } from "../../DataContext/DataContext";


const PerfilPrestador = () => {
  let { id } = useParams();
  const {turnos} = useData()
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(id), "clave_secreta");
  const info = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


  const [data, setData] = useState(info);
  const [historial, setHistorial] = useState([]);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
 
  useEffect(()=>{
    if(turnos){
      const seleccion =turnos.filter(
        (x) =>        
          Number(x.id_prestador) === Number(info.id)
      )
      setHistorial(seleccion)
    } 
  },[])

console.log(historial)
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePassword1 = () => {
    if (passwordType === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };


  function getData(i){
		setData(i); 
}
const [actualizado, setActualizado] = useState(false)
   
const pass = () => {
	if (password.length >0 && password === password1) {  
		let userInfo = {
            userId: info.id,
            password: password,
            password1:password1
        }; 
		
		axios.post(apiConfig+`prestadores/list/changePassword.php`, userInfo)
		.then((res) => {
		let {data} = res;                  
		if(data.success && data.message){              
				if(data.success === 1){ 
					setActualizado(true)
				}
		}
		})
		.catch(function (res) {
	
		});
	}	
 };
 const colorMap = {
  1: "#ffba57", // "solicitado"
  2: "#39afd1", // "reservado"
  3: "#3b82f6", // "confirmado"
  4: "#fa5c7c", // "suspendido"
  5: "#9e9e9e", // "no asistió"
  6: "#0acf97", // "finalizado"
};


  return (
    <Container>
      <div className="text-start">
        <NavLink exact to={"/config/prestadores"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#736CED" }}>
        <div className="card-body">
          <div className="text-center text-white">Información Prestador</div>
        </div>
      </div>
      <Row className="pt-3">
        <Col sm={12} md={12}>
          <Card>
            <Card.Body>
              <div className="perfil">
                <Imagen data={data} />
                <div>
                  <strong>
                    {data.nombre},{data.apellido}<br/>
                    <p className="text-muted">{data.especialidad_medica}</p>
                  </strong>
                </div>
              </div>
            </Card.Body>
          </Card>
          <br />

              <Card>
                <Card.Body>
                  <Tabs
                    defaultActiveKey="Perfil"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                  >
                    <Tab eventKey="Perfil" title="Perfil">
						<TabContent>
                      <div className="text-start">
                        <ul>
                          <li>
                            <strong>Nombre</strong>
                            <br />
                            {data.nombre},{data.apellido}
                          </li>
                          <li>
                            <strong>Teléfono</strong>
                            <br />
                            {data.telefono ? data.telefono : "No registrado"}
                          </li>
                          <li>
                            <strong>Correo electrónico</strong>
                            <br />
                            {data.email ? data.email : "No registrado"}
                          </li>
                        </ul>
                      </div>
					  </TabContent>
                    </Tab>
                    <Tab eventKey="Editar" title="Editar">
						<TabContent>
							<Row>
							<div className="col-md-6 col-sm-12 text-start">
								<h4>Editar perfil</h4> <br/>
								<Update data={data} getData={getData}/>
							</div>							
							<div className="col-md-6 col-sm-12 text-start">
								<h4>Cambiar contraseña</h4> <br/>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                        className="mb-2"
                      >
                        <FloatingLabel
                          controlId="floatingTotal"
                          label="Contraseña"
                          className="col-10"
                        >
                          <Form.Control
                            type={passwordType}
                            name="password"
                            placeholder=""
                            onChange={(e) => setPassword(e.target.value)}
                            minLength={6}
                            maxLength={6}
                          />
                        </FloatingLabel>
                        <div onClick={togglePassword}>
                          {passwordType === "password" ? (
                            <small>Mostrar</small>
                          ) : (
                            <small>Ocultar</small>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                        className="mb-3 "
                      >

                        <FloatingLabel
                          controlId="floatingTotal1"
                          label="Contraseña"
                          className="col-10"
                        >
                          <Form.Control
                            type={passwordType1}
                            name="password"
                            placeholder=""
                            onChange={(e) => setPassword1(e.target.value)}
                            minLength={6}
                            maxLength={6}
                          />
                        </FloatingLabel>
                        <div onClick={togglePassword1}>
                          {passwordType1 === "password" ? (
                            <small>Mostrar</small>
                          ) : (
                            <small>Ocultar</small>
                          )}
                        </div>
                      </div>
                      {password !== password1 ? 
                        <p>La contraseña no coinciden</p>
						:
						<p> </p>
					
					}
					{actualizado && <p className="text-success">Actualizado</p>}
                      <div className=" text-start">
                        <button
                          className="btn app-button  text-white shadow"
                          onClick={pass}
                        >
                          Cambiar
                        </button>
                      </div>
					  </div>
					  </Row>
					  </TabContent>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
   
          <br />
          <Card>
            <Card.Body className="text-start">
              <div>
                <h3>Historial</h3>
              </div>
              <div className="profile-scrollable">
                <ListGroup>
                  {historial ? (
                    historial.map((data, index) => (
                      <ListGroup.Item key={index}>
                        <Row>
                          <Col sm={12} md={6}>
                            <div>
                              <strong> {data.tratamiento}</strong>
                            </div>
                            <div>
                              {moment(data.fecha).format("DD-MM-YYYY")}{" "}
                              {data.hora}
                            </div>
                          </Col>
                          <Col className="text-start" sm={12} md={6}>
                            <div>
                              <strong>Paciente:</strong>{" "}
                              {data.nombre+','+data.apellido}
                            </div>
                            <div>
                            <strong>Estado:</strong>{" "}
                            <small
                                style={{
                                  background:
                                    colorMap[Number(data.id_estado)]||
                                    "green",
                                  color: "white",
                                  borderRadius: "3px",
                                  padding: "5px",
                                }}
                              >
                                <span>{data.estado}</span>
                              </small>
                            </div>
                            <div>
                             {data.observaciones && <>
                              <strong>Observaciónes:</strong>
                              <br /> {data.observaciones}
                             </>}
                            </div>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))
                  ) : (
                    <div>Sin registros</div>
                  )}
                </ListGroup>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6}></Col>
      </Row>
    </Container>
  );
};

export default PerfilPrestador;
