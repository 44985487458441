"use client";
import React from 'react';
import LicenciaPlanes from '../../components/LicenciaPlanes/LicenciaPlanes';
import LicenciaRenovacion from '../../components/LicenciaRenovacion/LicenciaRenovacion';


const Suscripcion = ({licencia}) => {

	const renovarLicencia = licencia ? licencia.estado : true;


	return (
		<div>
			{renovarLicencia ?
			<>
			<LicenciaPlanes/>
			</>
			:
			<>
			<LicenciaRenovacion idLicencia={licencia}/>			
			</>
			}
 		</div>
	);
};

Suscripcion.propTypes = {};

export default Suscripcion;