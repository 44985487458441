import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import {apiConfig} from "../../../services/apiConfig";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";

import { NavLink, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { useData } from "../../../DataContext/DataContext";

const Servicios = () => {
  const { user, getDataAll } = useData();
  let { id } = useParams();
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(id), "clave_secreta");
  const info = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));



  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [data, setData] = useState([]);
  const [editar, setEditar] = useState([]);
  const [registro, setRegistro] = useState({
    nombre: "",
    descripcion: "",
    duracion: "",
    precio: "",   
    categoria: Number(info.id),
    estado: 1,
    id_business_office: user.id_business_office,
    id_business_unit: user.id_business_unit,
  });
  function getData() {
    axios
      .get(
        apiConfig +
          "service/list/?id=" +
          Number(user.id_business_unit) +
          "&categoria=" +
          Number(info.id)
      )
      .then((getData) => {
        setData(getData.data);
        getDataAll()
      });
  }
  useEffect(() => {
    getData(user.id_business_unit)
  }, [ user.id_business_unit]);



  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(apiConfig + `service/add/index.php`, registro)
      .then((res) => {
        let { data } = res;
        if (Number(data.success) === 1) {
          getData();
          setShow(false);
        }
      })
      .catch(function (res) {});
  }
  function handleSubmit1(e) {
    console.log(editar)
    e.preventDefault();
    axios
      .post(apiConfig + `service/put/editar.php`, editar)
      .then((res) => {
        let { data } = res;
        if (Number(data.success) === 1) {
          getData();
          setShow1(false);
        }
      })
      .catch(function (res) {
        console.log(res);
      });
  }

  const handleChange = (e) => {
    setRegistro({ ...registro, [e.target.name]: e.target.value });
  };

  const handleChange1 = (e) => {
    setEditar({ ...editar, [e.target.name]: e.target.value });
  };

  function onErase(id) {
    axios.get(apiConfig + `service/delete/delete.php?id=${id}`).then(() => {
      getData();
    });
  }

  function onDelete(id) {
    axios.get(apiConfig + `service/delete/index.php?id=${id}`).then(() => {
      getData();
    });
  }

  function onActive(id) {
    axios.get(apiConfig + `service/delete/active.php?id=${id}`).then(() => {
      getData();
    });
  }
  const getMinutesText = (value) => {
    switch (value) {
      case 78:
        return '30 minutos';
      case 117:
        return '45 minutos';
      case 156:
        return '1 hora';
      case 195:
        return '1 hora 15 minutos';
      case 234:
        return '1 hora 30 minutos';
      case 273:
        return '1 hora 45 minutos';
      case 312:
        return '2 horas';
      case 351:
        return '2 horas 15 minutos';
      case 390:
        return '2 horas 30 minutos';
      case 429:
        return '2 horas 45 minutos';
      case 468:
        return '3 horas';
      default:
        return '';
    }
  };

  return (
    <div className="container" >
      <div className="text-start">
        <NavLink exact to={"/config/categoria"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#736CED" }}>
        <div className="card-body">
          <div className="text-center text-white">
            {info && info.nombre}
          </div>
        </div>
      </div>
      <div className="text-center " style={{ color: "#736CED" }}>
        <small>
           Agregar o actualiza el servicio.
        </small>
      </div>  
      <div className="text-end">
        <h1>
          <BsIcons.BsPlusCircleFill
            onClick={handleShow}
            style={{ color: "#736CED" }}
          />
        </h1>
      </div>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
        <h1>Agregar Servicio</h1>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingCodigo"
                label="Nombre *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="nombre"
                  placeholder="Nombre"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingDescripcion"
                label="Descripcion *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="descripcion"
                  placeholder="Descripción"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingPrecio"
                label="Precio *"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  name="precio"
                  placeholder="Precio"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
        
          <Col md={6}>
            <div className="text-center">
              <div>Duración </div>
            </div>
            <Form.Select
            type="text"
            name="duracion"
            required
            onChange={handleChange}
            >
                        <option value="78">30 minutos</option>
                        <option value="117">45 minutos</option>
                        <option value="156">1 hora</option>
                        <option value="195">1 Hora 15 minutos</option>
                        <option value="234">1 hora 30 minutos</option>
                        <option value="273">1 hora 45 minutos</option>
                        <option value="312">2 horas</option>
                        <option value="351">2 horas 15 minutos</option>
                        <option value="390">2 horas 30 minutos</option>
                        <option value="429">2 horas 45 minutos</option>
                        <option value="468">3 horas</option>
            </Form.Select>
          </Col>
          </Row>
          <div className="d-grid gap-2 pt-5">
            <button
              onClick={handleSubmit}
              className="btn app-button  text-white shadow"
            >
              Guardar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {data &&
        data.map((datos, index) => (
          <div key={index}>
            {Number(datos.estado) === 1 ? (
              <>
                <div className="card shadow mb-3 ">
                  <div className="card-body">
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <BsIcons.BsFillRecordFill
                          style={{ color: "#30ff24" }}
                        />
                      </div>
                      <div className="text-center" style={{ flex: 2 }}>
                        {datos.nombre}
                      </div>
                      <div style={{ flex: 2 }}>
                     {getMinutesText(Number(datos.duracion))}
                      </div>
                      <div style={{ flex: 3 }}>
                        {Number(datos.precio).toLocaleString("es-AR", {
                          style: "currency",
                          currency: "ARS",
                        })}
                       </div>
                        <div style={{ flex: 1 }}>                       
                          {Number(datos.estado) === 1 ? (
                            <div
                              
                              onClick={() => onDelete(datos.id)}
                            >
                              <h3>
                                <BsIcons.BsToggleOn />
                              </h3>
                            </div>
                          ) : (
                            <div                              
                              onClick={() => onActive(datos.id)}
                            >
                              <h3>
                                <BsIcons.BsToggleOff />
                              </h3>
                            </div>
                          )}
                        </div>
                          <div style={{ flex: 1 }}>
                            <div
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                              onClick={() => setEditar(datos)}
                            >
                           
                                <h3>
                                  <BsIcons.BsThreeDotsVertical
                                    onClick={handleShow1}
                                  />
                                </h3>
                             
                            </div>
                          </div>
                       
                      </div>
                    </div>
                  </div>
               
                <Modal show={show1} size="lg" onHide={handleClose1}>
                  <Modal.Header closeButton>
                    <h1>Editar Servicio</h1>
                  </Modal.Header>
                  <Modal.Body>
                     <Row>
                      <Col md={6}>
                        <FloatingLabel
                          controlId="floatingCodigo"
                          label="Nombre *"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="nombre"
                            placeholder="Nombre"
                            value={editar && editar.nombre}
                            onChange={handleChange1}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <FloatingLabel
                          controlId="floatingDescripcion"
                          label="Descripcion *"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="descripcion"
                            value={editar && editar.descripcion}
                            placeholder="Descripción"
                            onChange={handleChange1}
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FloatingLabel
                          controlId="floatingPrecio"
                          label="Precio *"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            name="precio"
                            placeholder="Precio"
                            value={editar && Number(editar.precio)}
                            onChange={handleChange1}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                      <div className="text-center">
                        <div>Duración</div>
                      </div>
                      <Form.Select
                      type="text"
                      name="duracion"
                      required
                      onChange={handleChange1}
                      >
                        <option value={registro && registro.duracion}>{getMinutesText(Number(registro.duracion))}</option>
                        <option value="78">30 minutos</option>
                        <option value="117">45 minutos</option>
                        <option value="156">1 hora</option>
                        <option value="195">1 Hora 15 minutos</option>
                        <option value="234">1 hora 30 minutos</option>
                        <option value="273">1 hora 45 minutos</option>
                        <option value="312">2 horas</option>
                        <option value="351">2 horas 15 minutos</option>
                        <option value="390">2 horas 30 minutos</option>
                        <option value="429">2 horas 45 minutos</option>
                        <option value="468">3 horas</option>
                      </Form.Select>
                      </Col>
                    </Row>
                    <div className="d-grid gap-2 pt-5">
                      <button
                        onClick={handleSubmit1}
                        className="btn app-button  text-white shadow"
                      >
                        Guardar
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            ) : (
              <div className="card shadow mb-3 ">
                <div className="card-body">
                <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                    <div style={{ flex: 1 }}>
                      <BsIcons.BsFillRecordFill style={{ color: "#f80f00" }} />
                    </div>
                    <div className="text-center" style={{ flex: 2 }}>
                      {datos.nombre}
                    </div>
                    <div style={{ flex: 2 }}>
                    {getMinutesText(Number(datos.duracion))}
                    </div>
                    <div style={{ flex: 3 }}>
                      {Number(datos.precio).toLocaleString("es-AR", {
                        style: "currency",
                        currency: "ARS",
                      })}
                    </div>
                    <div style={{ flex: 1 }}>
                      
                        {Number(datos.estado) === 1 ? (
                          <div
                            
                            onClick={() => onDelete(datos.id)}
                          >
                            <h3>
                              <BsIcons.BsToggleOn />
                            </h3>
                          </div>
                        ) : (
                          <div
                           
                            onClick={() => onActive(datos.id)}
                          >
                            <h3>
                              <BsIcons.BsToggleOff />
                            </h3>
                          </div>
                        )}
                        </div>
                        <div style={{ flex: 1 }}>
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Eliminar"
                            onClick={() => onErase(datos.id)}
                          >
                            <h3>
                              <BsIcons.BsTrash />
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          
            )}
          </div>
        ))}
    </div>
  );
};

export default Servicios;
