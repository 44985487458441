import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import useAuth from "../../auth/useAuth";
import {apiConfig} from "../../services/apiConfig";
import Profile from "../profile/Profile";
import Badge from 'react-bootstrap/Badge';
import logo from "../../assets/image/logowhite.svg";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import "./Navb.css";
import { NavLink } from "react-router-dom";
import CryptoJS from "crypto-js";

function Navb(props) {
  const auth = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(auth);
  const [qdia, setQdia] = useState("");
  const [show, setShow] = useState(false);
  const [notificacion,setNotificacion] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const mostrarSaludo = () => {
    var fecha = new Date();
    let hora = fecha.getHours();
    if (hora >= 0 && hora < 12) {
      setQdia("Buenos Días");
    }
    if (hora >= 12 && hora < 20) {
      setQdia("Buenas Tardes");
    }
    if (hora >= 20 && hora < 24) {
      setQdia("Buenas Noches");
    }
  };

  const actualizarDatos = (id) => {
    axios
      .get(`${apiConfig}profile/list/user.php?id=${id}`)
      .then((res) => {
        let { data } = res;
        setUser(data[0]);
      })
      .catch(function (res) {});
  };
  useEffect(() => {
    mostrarSaludo();
  }, [qdia]);
  return (
    <>
      <nav
        className="navbar navbar-light fixed-top shadow "
        style={{ background: "#736CED" }}
      >
        <div className="container-fluid">
          <div className="navbar-brand d-none d-sm-none d-md-block">
            <img
              src={logo}
              alt="cronogram"
              className=" App-logo-sm"              
            />
          </div>
          <form className="d-flex">
          <div className="text-white" style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            paddingRight: '20px',
          }}
          >
            <NavLink exact to="/config" className="text-white">          
                <BsIcons.BsBell style={{ fontSize: "1.8rem" }} />
                {notificacion.length === 0 ?
                  <Badge bg="secondary">0</Badge>
                  :
                  <Badge bg="danger">{notificacion.length}</Badge>  
                }         
            </NavLink>
          </div>
            <div onClick={handleShow}>
              <img
                src={`${apiConfig}profile/image/${user.avatar_url}`}
                style={{
                  width: "40px",
                  height: "auto",
                  borderRadius: "50px",
                  border: "solid 3px #ffffff42",
                }}
                alt="img-profile"
              />
            </div>
            <Offcanvas show={show} placement={"end"} onHide={handleClose}>
              <Offcanvas.Header closeButton style={{background: '#736ced', color:'white'}}>
                <Profile actualizarDatos={actualizarDatos} user={user} />
              </Offcanvas.Header>
              <Offcanvas.Body>
                
                <div>
                    <div className="mb-4 pt-2">
                      <NavLink exact  to={
                            "/profile/update/" +
                            encodeURIComponent(
                              CryptoJS.AES.encrypt(
                                JSON.stringify(user),
                                "clave_secreta"
                              ).toString()
                            )
                          } >
                        <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                        <span >Perfil</span><BsIcons.BsChevronRight />
                        </div>
                      
                        </NavLink>
                    </div>                
                    <div className="mb-4">
                    <NavLink exact to="/calendario" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                    <span style={{color: 'black'}}>Agenda</span><BsIcons.BsChevronRight />
                    </div>
                      </NavLink>
                    </div>
                 
                    <div className="mb-4">
                    <NavLink exact to="/reporte" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                      <span style={{color: 'black'}}>Reportes</span><BsIcons.BsChevronRight />
                      </div>
                      </NavLink>
                    </div>                 
                  <hr/>
                    <div className="mb-4">
                    <NavLink exact to="/config" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                      <span style={{color: 'black'}}>Parametros</span><BsIcons.BsChevronRight />
                      </div>
                      </NavLink>
                    </div>
                   </div>
                   <div className="mb-4">
                    <NavLink exact to="/Home" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                      <span style={{color: 'black'}}>Suscripción</span><BsIcons.BsChevronRight />
                      </div>
                      </NavLink>
                    </div>
                    <hr/>
                    <div className="mb-4">
                    <NavLink exact to="/Home" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                      <span style={{color: 'black'}}>Ayuda</span><BsIcons.BsChevronRight />
                      </div>
                      </NavLink>
                    </div>
                    <div className="mb-4">
                    <NavLink exact to="/Home" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                      <span style={{color: 'black'}}>Acerca de Cronogram</span><BsIcons.BsChevronRight />
                      </div>
                      </NavLink>
                    </div>
                    <hr/>
                    <div className="mb-4">
                    <NavLink exact to="/terminos" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                      <span style={{color: 'black'}}>Términos y condiciones</span><BsIcons.BsChevronRight />
                      </div>
                      </NavLink>
                    </div>
                  <div
                    className="fixed-footer text-center mb-2 pt-5"
                    style={{ color: "#736CED", cursor: "pointer" }}
                    onClick={auth.logoutUser}
                  >
                    Cerrar sesión
                  
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </form>
        </div>
      </nav>
    </>
  );
}
export default Navb;
