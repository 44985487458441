import React, {useState} from 'react';
import axios from "axios";
import {apiConfig} from "../../../services/apiConfig";
import { Form, Row, Col, Button, FloatingLabel, FormGroup } from 'react-bootstrap';
import { useData } from '../../../DataContext/DataContext';

const Update = (props) => {
	const { locales, getDataAll } = useData();

    const [datoSeleccionado,setDatoSeleccionado] = useState(props.data);

	function updateUser(event){           
			event.preventDefault();
			axios.post(apiConfig+`prestadores/update/update.php`,datoSeleccionado)
			.then((res) => {
				let {data} = res;                  
				if(data.success && data.message){ 			  }
				getDataAll()
			})
			.catch(function (res) {
				
			});		
	}

	const handleChange = (e) => {        
		setDatoSeleccionado({...datoSeleccionado,[e.target.name]:e.target.value});		
	}




	return  <div className="text-start">
	  <div className="col-12 pt-1">
		<Form onSubmit={updateUser}>
		  <Row>
			<Col sm={6} className="mb-2">
			  <FloatingLabel label="Nombre">
				<Form.Control
				  name="nombre"
				  type="text"
				  value={datoSeleccionado && datoSeleccionado.nombre}
				  onChange={handleChange}
				  pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
				  maxLength={20}
				/>
			  </FloatingLabel>
			</Col>
			<Col sm={6} className="mb-2">
			  <FloatingLabel label="Apellido">
				<Form.Control
				  name="apellido"
				  type="text"
				  value={datoSeleccionado && datoSeleccionado.apellido}
				  onChange={handleChange}
				  pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
				  maxLength={20}
				/>
			  </FloatingLabel>
			</Col>
		  </Row>
		  <Form.Group className="mb-2">
			<FloatingLabel label="Teléfono">
			  <Form.Control
				name="telefono"
				type="text"
				value={datoSeleccionado && datoSeleccionado.telefono}
				onChange={handleChange}
				minLength={10}
				maxLength={10}
				pattern="[0-9]+$"
			  />
			</FloatingLabel>
		  </Form.Group>
		  <Form.Group className="mb-2">
			<FloatingLabel label="Correo electrónico">
			  <Form.Control
				name="email"
				type="email"
				value={datoSeleccionado && datoSeleccionado.email}
				onChange={handleChange}
			  />
			</FloatingLabel>
			</Form.Group>
		  <Form.Group className="mb-2">
			<FloatingLabel controlId="floatingSelect" label="Local">
     			<Form.Select aria-label="Local"		
				as="select"
				name="id_business_office"
				value={datoSeleccionado && datoSeleccionado.id_business_office}
				onChange={handleChange}
				required
			  >
						<option selected></option>
						{locales && locales.map((data,index)=>(
							<option value={data.id} key={index}>{data.name}</option>
						))}
			  	</Form.Select>
    		</FloatingLabel>
		  </Form.Group>
		  <FormGroup className="mb-2">
		  <FloatingLabel controlId="especialidad" label="Especialidad">
            <Form.Control
              name="especialidad_medica"
              type="text"
              className="form-control"
              value={datoSeleccionado && datoSeleccionado.especialidad_medica} 
              onChange={handleChange}       
           
            />
          </FloatingLabel>
		  </FormGroup>

	
	
		  <br />
		  <div className="mb-4">
			<Button type="submit" className="btn app-button shadow">
			  Guardar
			</Button>
		  </div>
		</Form>
	  </div>
	</div>;
	
};

export default Update;
