import React, { useState } from "react";
import "moment/locale/es";
import {  Table, Modal, Container } from "react-bootstrap";
import moment from "moment";
import * as BsIcons from "react-icons/bs";

const TablaVentas = ({turnos, pagos, mes}) => {
	const [show, setShow] = useState(false);
	const [datoSeleccionado, setDatoSeleccionado] = useState([]);
	function handleShow(a) {
	  setDatoSeleccionado(a);
	  setShow(true);
	}
	const colorMap = {
		1: "#fa5c7c", // "solicitado"
		2: "#0acf97", // "reservado"
	  };

	  const imprimirPantalla = () => {
		window.print();
	  }

    const totalTurnoPrecio=(turno)=>{
      const lista = turno
      .filter(
        (x) =>
          moment(x.fecha).format("YYYY-MM") ===
          moment(mes).format("YYYY-MM")
      )
      const total = lista.reduce((acc, curr) => acc + Number(curr.precio), 0)
      const formato = Number(total).toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 2,
      })
      return formato
    }
	return (
		<div className="col-md-12 col-sm-12 d-none d-sm-none d-md-block">
		<div className="card">
		  <div className="card-body ">
			<h5>Ventas</h5>
			<Table striped bordered hover>
			  <thead>
				<tr>
				  <th scope="col">#</th>
				  <th>Código</th>
				  <th>Nombre</th>
				  <th>Concepto</th>
				  <th>Prestador</th>
				  <th>Monto</th>
          <th>Estado</th>
				</tr>
			  </thead>
			  <tbody>
				{turnos ? (
				  turnos
					.sort((a, b) => {
					  // Convierte las fechas a objetos Date
					  const fechaHoraA = new Date(`${a.fecha} ${a.hora}`);
					  const fechaHoraB = new Date(`${b.fecha} ${b.hora}`);

					  // Compara primero por fecha
					  if (fechaHoraA.getDate() !== fechaHoraB.getDate()) {
						return (
						  fechaHoraA.getDate() - fechaHoraB.getDate()
						);
					  } else {
						// Si las fechas son iguales, compara por hora
						return (
						  fechaHoraA.getHours() - fechaHoraB.getHours() ||
						  fechaHoraA.getMinutes() -
							fechaHoraB.getMinutes()
						);
					  }
					})
					.map((a, i) => (
					  <tr key={i} onClick={() => handleShow(a)} style={{cursor:'pointer'}}>
						<td>{i + 1}</td>
						<td>#{a.codigo}</td>
						<td>{a.nombre + " ," + a.apellido}</td>
						<td>
						  <small>{a.tratamiento}</small>
						  <br />
						  <small>
							{moment(a.fecha).format("DD-MM-YYYY")}{" "}
							{a.hora}
						  </small>
						</td>
						<td>{a.p_nombre + ", " + a.p_apellido}</td>
						<td className="text-end">
					
							{Number(a.precio).toLocaleString("es-AR", {
							  style: "currency",
							  currency: "ARS",
							  minimumFractionDigits: 2,
							})}
				

						 
						</td>
            <td >
            <small
							style={{
							  background:
								Number(a.id_estado_pago) === 1 && pagos.filter((x)=> x.id_turnos === a.id).length > 0 && '#dda600' || Number(a.id_estado_pago) === 1 && pagos.filter((x)=> x.id_turnos === a.id).length <= 0 && 'red'  ||
								"green",
							  color: "white",
							  borderRadius: "3px",
							  padding: "5px",
							}}
						  >
							<span>{a.estado_pago}</span>
						  </small>
            </td>
					  </tr>
					))
				) : (
				  <tr>
					<td>Sin registros</td>
				  </tr>
				)}
			  </tbody>
        <tfoot>
          <tr>
            <td colspan="5" className="text-end"><strong>Total</strong></td>
            <td className="text-end">{totalTurnoPrecio(turnos)}</td>
          </tr>
        </tfoot>

			</Table>
		  </div>
		</div>
		<Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Detalle venta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {datoSeleccionado && (
                <Container>
                  <div
                    className="mb-3"
                    style={{
                    //  boxShadow: "0px 4px 15px rgba(79,79,79,0.1)",
                      fontSize: "0.8rem",
                      padding: "15px",
                      margin: "5px",
                      borderRadius: "2px",
                    }}
                    id="divAImprimir"
                  > 
                  <div className="text-end h1">
                    <BsIcons.BsPrinter onClick={imprimirPantalla}/>
                  </div>
                    <div className="text-center pt-5">
                      <div>
                        <h3>{datoSeleccionado.tratamiento}</h3>
                        <small>Total pagado</small>
                        <br />
                        <h1 className="display-3" style={{ color: "#736CED" }}>
                          <strong>
                            {pagos && pagos.filter(
                        (x) =>
                          Number(x.id_turnos) === Number(datoSeleccionado.id)).length > 0 ?
                      pagos.filter(
                        (x) =>
                          Number(x.id_turnos) === Number(datoSeleccionado.id)
                      ).reduce((suma, pago) => suma + Number(pago.monto), 0).toLocaleString("es-AR", {
                              style: "currency",
                              currency: "ARS",
                              minimumFractionDigits: 2,
                            }) 
                            : Number(0).toLocaleString("es-AR", {
                              style: "currency",
                              currency: "ARS",
                              minimumFractionDigits: 2,})}
                          </strong>
                        </h1>
                        <strong>
                                <h5>
                                Saldo a pagar {(Number(datoSeleccionado.precio) - pagos.filter(
                        (x) =>
                          Number(x.id_turnos) === Number(datoSeleccionado.id)
                      ).reduce((suma, pago) => suma + Number(pago.monto), 0)).toLocaleString("es-AR", {
                        style: "currency",
                        currency: "ARS",
                        minimumFractionDigits: 2,
                      }) }
                                </h5>
                        </strong>
         
                      </div>
                    </div>

                    <div
                      className="pt-2 mb-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="payTitulo">{datoSeleccionado.nombre+','+datoSeleccionado.apellido}</div>
                      <div className="payTitulo">
                        {" "}
                        {moment().format("DD-MM-YYYY")}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <div className="text-start">
                          Código
                          <br />
                          <strong>{datoSeleccionado.codigo}</strong>
                        </div>
                        <div>
                        {datoSeleccionado.tratamiento}
                          <br />
                         
                          {moment(datoSeleccionado.fecha).format("DD-MM-YYYY")}{" "}
                          {datoSeleccionado.hora}
                        </div>
                     
                      </div>
                      <div>
                        {Number(datoSeleccionado.precio).toLocaleString("es-AR", {
                          style: "currency",
                          currency: "ARS",
                          minimumFractionDigits: 2,
                        })}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    ></div>
                    <br />
                    {pagos &&
                      pagos.filter(
                        (x) =>
                          Number(x.id_turnos) === Number(datoSeleccionado.id)
                      ).length > 0 && (
                        <>
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col" className="text-start">
                                  Nº. Transacción
                                </th>
                                <th scope="col" className="text-start">
                                  Fecha de pagó
                                </th>
                                <th scope="col" className="text-start">
                                  Medio
                                </th>
                                <th scope="col" className="text-end">
                                  Importe
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {pagos
                                .filter(
                                  (x) =>
                                    Number(x.id_turnos) ===
                                    Number(datoSeleccionado.id)
                                )
                                .map((data, i) => {
                                  return (
                                    <tr key={i}>
                                      <td className="text-start">
                                        <small>
                                        {data.transaccion !== ""
                                  ? data.transaccion
                                  : data.transaccionMp+'MP'}
                                        </small>
                                      </td>
                                      <td className="text-start">
                                        <small>
                                          {moment(data.fecha_pago).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </small>
                                      </td>
                                      <td className="text-start">
                                        <small>
                                          {Number(data.id_medio_pago) === 1
                                            ? "Efectivo"
                                            : "Mercado Pago"}
                                        </small>
                                      </td>
                                      <td className="text-end">
                                        <small>
                                          {Number(data.monto).toLocaleString(
                                            "es-AR",
                                            {
                                              style: "currency",
                                              currency: "ARS",
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </small>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      )}
                  </div>
                </Container>
              )}
            </Modal.Body>
          </Modal>
	  </div>
	);
};



export default TablaVentas;