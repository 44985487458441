import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { apiConfig } from "../../../services/apiConfig";
import * as BsIcons from "react-icons/bs";
import { ListGroup } from "react-bootstrap";
import CustomerUpdate from "../CustomerUpdate/CustomerUpdate";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { NavLink, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import Imagen from "./Imagen";
import DetalleVenta from "../../../components/DetalleVenta/DetalleVenta";

const HistorialCliente = () => {
  let { id } = useParams();
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(id), "clave_secreta");
  const info = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  const [cliente, setCliente] = useState(info);
  const [historial, setHistorial] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");

  const [actualizado, setActualizado] = useState(false);

  const pass = () => {
    if (password.length > 0 && password === password1) {
      let userInfo = {
        userId: info.id_cliente,
        password: password,
        password1: password1,
      };

      axios
        .post(apiConfig + `customer/list/changePassword.php`, userInfo)
        .then((res) => {
          let { data } = res;
          if (data.success && data.message) {
            if (data.success === 1) {
              setActualizado(true);
            }
          }
        })
        .catch(function (res) {});
    }
  };

  useEffect(() => {
    axios
      .get(
        apiConfig + "turnos/list/individual.php?id=" + Number(info.id_cliente)
      )
      .then((getData) => {
        setHistorial(getData.data);
      });
  }, [cliente.id_cliente]);
  const actualizaInfo = (e) => {
    setCliente(...[e]);
    setShow(false);
  };

  const [datoSeleccionado, setDatoSeleccionado] = useState([]);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const handleDetalleClick = (e) => {
    setDatoSeleccionado(e);
    setShow1(true);
  };

  return (
    <Container>
      <div className="text-start">
        <NavLink exact to={"/config/customer"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#736CED" }}>
        <div className="card-body">
          <div className="text-center text-white">Información Cliente</div>
        </div>
      </div>
      <Row className="pt-3">
        <Col sm={12} md={12}>
          <Card>
            <div className="container text-center pt-5">
              <Imagen cliente={cliente} />
              <div className="pt-3">
                <strong>{cliente.seudonimo}</strong>
              </div>
              <Row>
                <Col>
                  <div>
                    <strong>Reservas</strong>
                    <br />
                    {historial ? historial.length : "0"}
                  </div>
                </Col>
                <Col style={{ borderLeft: "solid 1px #ededed" }}>
                  <div>
                    <strong>Última</strong>
                    <br />
                    {historial.length > 0
                      ? moment(historial[0].fecha).format("DD-MM-YYYY")
                      : "-"}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <br />
          <Card>
            <Card.Body>
              <Row>
                <div className="text-center pt-3">
                  <h3>Datos personales</h3>
                </div>
                <Col
                  sm={12}
                  md={6}
                  className="text-start"
                  style={{ borderLeft: "solid 1px #ededed" }}
                >
                  <ul>
                    <li>
                      <strong>Nombre</strong>
                      <br />
                      {cliente.nombre + ", " + cliente.apellido}
                    </li>
                    <li>
                      <strong>Teléfono</strong>
                      <br />
                      {cliente.telefono ? cliente.telefono : "No registrado"}
                    </li>
                    <li>
                      <strong>Correo electrónico</strong>
                      <br />
                      {cliente.email ? cliente.email : "No registrado"}
                    </li>
                    <li>
                      <strong>Fecha nacimiento</strong>
                      <br />
                      {cliente.fecha_nac ? cliente.fecha_nac : "No registrado"}
                    </li>
                  </ul>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="text-start"
                  style={{ borderLeft: "solid 1px #ededed" }}
                >
                  <ul>
                    <li>
                      <strong>Dirección</strong>
                      <br />
                      {cliente.direccion ? cliente.direccion : "No registrado"}
                    </li>
                    <li>
                      <strong>Ciudad</strong>
                      <br />
                      {cliente.ciudad ? cliente.ciudad : "No registrado"}
                    </li>
                    <li>
                      <strong>Provincia</strong>
                      <br />
                      {cliente.provincia ? cliente.provincia : "No registrado"}
                    </li>
                    <li>
                      <strong>Codígo Postal</strong>
                      <br />
                      {cliente.codigo_postal
                        ? cliente.codigo_postal
                        : "No registrado"}
                    </li>
                  </ul>
                </Col>
              </Row>
              <div>
                <Button className="me-2 mb-2" onClick={() => handleShow()}>
                  Editar
                </Button>
              </div>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body className="text-start">
              <div>
                <h3>Historial</h3>
              </div>
              <div className="profile-scrollable">
                <ListGroup>
                  {historial ? (
                    historial.map((data, index) => (
                      <ListGroup.Item
                        key={index}
                        onClick={() => handleDetalleClick(data)}
                        className="seleccion"
                      >
                        <Row>
                          <Col sm={12} md={6}>
                            <div>
                              <strong> {data.tratamiento}</strong>
                            </div>
                            <div>
                              {moment(data.fecha).format("DD-MM-YYYY")}{" "}
                              {data.hora}
                            </div>
                            <div>
                              <strong>Estado de pago:</strong>{" "}
                              {data.estado_pago === "Pendiente" && (
                                <span style={{ color: "red" }}>
                                  {data.estado_pago}
                                </span>
                              )}
                              {data.estado_pago === "Pago parcial" && (
                                <span style={{ color: "#ffba57" }}>
                                  {data.estado_pago}
                                </span>
                              )}
                              {data.estado_pago === "Pagado" && (
                                <span style={{ color: "#0acf97" }}>
                                  {data.estado_pago}
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col className="text-start" sm={12} md={6}>
                            <div>
                              <strong>Importe:</strong>{" "}
                              {Number(data.precio) > 0
                                ? Number(data.precio).toLocaleString("es-AR", {
                                    style: "currency",
                                    currency: "ARS",
                                    minimumFractionDigits: 2,
                                  })
                                : "$ 0,00"}
                            </div>
                            <div>
                              <strong>Prestador:</strong>{" "}
                              {data.p_nombre + ", " + data.p_apellido}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <div >
                            {data.observaciones && (
                              <>
                                <strong>Observaciónes:</strong>
                                <br />
                                {data.observaciones}
                              </>
                            )}
                          </div>
                        </Row>
                      </ListGroup.Item>
                    ))
                  ) : (
                    <div>Sin registros</div>
                  )}
                </ListGroup>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6}></Col>
      </Row>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <CustomerUpdate item={cliente} actualizaInfo={actualizaInfo} />
        </Modal.Body>
      </Modal>
      <Modal show={show1} fullscreen={true} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <DetalleVenta datoSeleccionado={datoSeleccionado} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default HistorialCliente;
