import React, { useState } from "react";
import { NavLink } from "react-router-dom";
const LicenciaPlanes = ({}) => {
  const [planes, setPlanes] = useState([
    {
      id: 1,
      precio: 10500,
      nombre: "Emprendedor",
      descuento: 50,
    },
    {
      id: 3,
      precio: 19500,
      nombre: "Avanzado",
      descuento: 50,
    },
    {
      id: 4,
      precio: 39500,
      nombre: "Pro",
      descuento: 50,
    },
  ]);

  return (
    <div className="container ">
      <div className="row pt-5">
        <h1>Elegí el plan</h1>
      </div>
      <div className="row pt-5 text-start">
        {planes &&
          planes.map((x, i) => (
            <div className="col-md-3 col-sm-12 mx-auto">
              <div className="text-center">
                <div className="text-center">
                  <h2>{x.nombre}</h2>
                  <br />
                  <small className="text-muted">
                    $AR{" "}
                    {x.precio.toLocaleString("es-AR", {
                      minimumFractionDigits: 2,
                    })}
                  </small>
                  <br />
                  <span
                    className="badge"
                    style={{ backgroundColor: "#ebe4ff", color: "#5025d1" }}
                  >
                    Ahorras %{x.descuento}
                  </span>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      gap: 5,
                    }}
                  >
                    <small className="text-muted"> $AR</small>
                    <h1>
                      {(
                        x.precio -
                        (x.precio * x.descuento) / 100
                      ).toLocaleString("es-AR", {
                        minimumFractionDigits: 2,
                      })}
                    </h1>
                    <small className="text-muted">/Mes</small>
                  </div>
                  <div className="text-center pt-3 mb-3">
                    <NavLink exact to="#">
                      <div
                        className=" btn btn-danger"
                        style={{
                          backgroundColor: "#fc5185",
                          border: "1px solid #fc5185",
                          boxShadow:
                            "0 2px 4px 0 rgba(136, 144, 195, 0.2), 0 5px 15px 0 rgba(37, 44, 97, 0.15)",
                          color: "white",
                        }}
                      >
                        Siguiente
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <ul className="icon-list bullet-green mt-2 mb-0 pt-3 text-start">
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Agenda de citas ilimitadas</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Gestión de clientes</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Reportes de gestión</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Migración de contactos</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>
                Recordatorios masivos a través de whatsapp (Solo notificaciónes)
              </span>
            </li>
          </ul>
        </div>

        <div className="col-md-4 col-sm-12 ">
          <ul className="icon-list bullet-green mt-2 mb-0 pt-3 text-start">
            <li className="mb-3">
              <i className="fs-22 uil uil-arrow-circle-left" />
              <span className="text-dark">
                Todas las funcionalidades de emprendedor, más:
              </span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Notificaciónes y alertas diarias</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>
                Recordatorios con link pago a través de whatsapp (Solo
                notificaciónes)
              </span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Integración Mercado Pago</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Link de Pagos</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>
                Sitio web de reservas con catálogo web que exhibe todos los
                servicios y permite reservar 24/7.
              </span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>
                Métricas y estadísticas de reservas, pagos y ocupación. Con
                exportación a excel.
              </span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Perfil y roles para asignar usuarios</span>
            </li>
          </ul>
        </div>
        <div className="col-md-4 col-sm-12 ">
          <ul className="icon-list bullet-green mt-2 mb-0 text-start pt-3">
            <li className="mb-3">
              <i className="fs-22 uil uil-arrow-circle-left" />
              <span className="text-dark">
                Todas las funcionalidades del Avanzado, más:
              </span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Crea locales</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Personalización de aplicación</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Integracion whatsapp(Numero propio)</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>App para clientes</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Servidor dedicado</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                viewBox="0 -960 960 960"
                width={28}
                fill="#46ff51"
              >
                <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z" />
              </svg>
              <span>Soporte y desarrollo</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LicenciaPlanes;
