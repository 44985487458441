import {useState} from 'react';
import * as BsIcons from 'react-icons/bs';
import axios from 'axios';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {apiConfig} from '../../../services/apiConfig';
import Localidades from '../../../services/Localidades/Localidades';
import './styles/ItemUpdate.css';


function SubItemUpdate(props) {

    let item = props.item

	const state =  item ;	
	const vista = true ;
	
	

	const [infos,setInfos] = useState(
		{
			id_cliente: item.id_cliente,
			nombre: item.nombre,
			apellido: item.apellido,
			email: item.email,
			telefono: item.telefono,
			fecha_nac: item.fecha_nac,
			direccion: item.direccion,
			ciudad: item.ciudad,
			provincia: item.provincia,
			pais: item.pais,
			codigo_postal: item.codigo_postal,
			img: item.img,
			id_business_office: item.id_business_office,
			id_business_unit: item.id_business_unit,
		}
	);


    const handleChange = (e) => {        
        setInfos({...infos,[e.target.name]:e.target.value});		
    }

    const handleSubmit = (e) => {
        e.preventDefault();	
        axios.post(apiConfig+`customer/update/index.php`, infos)
        .then((res) => {
            let data = res.data			
			if(Number(data.success) === 1){							
				
				props.actualizaInfo(infos)	
			}               
        })
        .catch(function (res) {
            
        });
       
    }
function onDelete(id){
	
	
}


	

  return (
	<div className='container'>
		{vista &&
		<div className='container' >
				<div className="card-body">
						<div className="text-end text-muted mb-3">
							<small>* Campos obligatorios</small>
						</div>
						{state  &&
						<form >
						<Row>
							<Col md={6}>
								<FloatingLabel controlId="floatingCodigo" label="Nombre *" className="mb-3">
									<Form.Control type="text" name="nombre" value={infos && infos.nombre} placeholder="Nombre" onChange={handleChange} required/>
								</FloatingLabel>
							</Col>
							<Col md={6}>
								<FloatingLabel	controlId="floatingDescripcion" label="Apellido *"	className="mb-3">
									<Form.Control type="text" name="apellido" value={infos && infos.apellido} placeholder="Apellido" onChange={handleChange} required/>
								</FloatingLabel>
							</Col>
						</Row>
						<Row>
							<Col>
								<FloatingLabel controlId="floatingTotal" label="teléfono *" className="mb-3">
									<Form.Control type="text" name="telefono" value={infos && infos.telefono} placeholder="teléfono" onChange={handleChange} required/>
								</FloatingLabel>
							</Col>
							<Col>
								<FloatingLabel	controlId="floatingDescripcion" label="Email *"	className="mb-3">
									<Form.Control type="text" name="email" value={infos && infos.email} placeholder="email" onChange={handleChange} required/>
								</FloatingLabel>
							</Col>							
						</Row>
						<Row>
							<Col>
								<FloatingLabel controlId="floatingActual" label="Fecha de nacimiento" className="mb-3">
									<Form.Control type="date" name="fecha_nac" value={infos && infos.fecha_nac} placeholder="Fecha de nacimiento"  onChange={handleChange} />
								</FloatingLabel>
							</Col>
							<Col>
								<FloatingLabel controlId="floatingTotal" label="Dirección " className="mb-3">
									<Form.Control type="text" name="direccion" value={infos && infos.direccion} placeholder="Dirección" onChange={handleChange} />
								</FloatingLabel>
							</Col>
						</Row>
						<Row>
							<Col>
								<FloatingLabel controlId="floatingProvincia" label="Provincia *" className="mb-3">									
									<select type="text" name="provincia"  className='form-control'  value={infos && infos.provincia} onChange={handleChange} required>
										<option  style={{color: '#888'}}></option>
										{Localidades && Localidades.map((data,index)=>{
											return (<option key={index} value={data.nombre} style={{color: '#888'}}>{data.nombre}</option>)
										})}
										</select>
								</FloatingLabel>
							</Col>
							<Col>
							<FloatingLabel controlId="floatingLocalidad" label="Localidad *" className="mb-3">
								<select type="text" name="ciudad" className='form-control' value={infos && infos.ciudad} onChange={handleChange} required>
								<option   style={{color: '#888'}}></option>
								{infos.provincia.length > 0 && Localidades.filter((x)=> x.nombre.includes(infos.provincia)).map((data)=>{
									return ( 
									<>{data.ciudades.map((dat,index)=>{
										return (
											<option key={index} value={dat.nombre}>{dat.nombre}</option>
										)
									})}
									</>)
								})}
								</select>
								</FloatingLabel>
							</Col>							
						</Row>
						<Row>
							<Col>
								<FloatingLabel controlId="floatingTotal" label="Codígo postal" className="mb-3">
									<Form.Control type="text" name="codigo_postal" value={infos && infos.codigo_postal} placeholder="Codígo postal" onChange={handleChange} required/>
								</FloatingLabel>
							</Col>
							<Col>
								<FloatingLabel controlId="floatingActual" label="País" className="mb-3">
									<Form.Control type="text" name="pais" placeholder="País" value="Argentina"  onChange={handleChange} />
								</FloatingLabel>
							</Col>
						</Row>
						<div className='pt-3 text-end active'>
							<h1><BsIcons.BsTrash onClick={()=> onDelete(infos.id_cliente)}/></h1>
						</div>
						
						<div className="d-grid gap-2 pt-5">
							<button onClick={handleSubmit} className="btn app-button  text-white shadow"  >Guardar</button>
						</div>
						</form>							
							}									
					</div>
				</div>            

		}
	  </div>
  )
}

export default SubItemUpdate;
