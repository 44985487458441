import React, { useState, useEffect } from 'react';
import * as BsIcons from "react-icons/bs";
import moment from "moment";
import "moment/locale/es";

const Calendario=({fecha, profesional, turnos, actualizarFechas})=> {
      const [fechaSeleccionada, setFechaSeleccionada] = useState(fecha);
      const [mesActual, setMesActual] = useState(new Date().getMonth());
      const [añoActual, setAñoActual] = useState(new Date().getFullYear());
      const [reservas,setReservas] = useState(turnos);
      const [porfesional,setProfesional] = useState(profesional);
      
      
      const obtenerFecha = (dia) => {
          setFechaSeleccionada(dia);
      };
    
      useEffect(()=>{
        actualizarFechas(fechaSeleccionada)
      },[fechaSeleccionada])

      useEffect(()=>{
        setReservas(turnos)        
      },[turnos])

      useEffect(()=>{
        setProfesional(profesional)
      },[profesional])

     
    
      const generarCalendario = () => {
        const fechaActual = new Date()
        const primerDiaMes = new Date(añoActual, mesActual, 1);
        const ultimoDiaMes = new Date(añoActual, mesActual + 1, 0);
        const primerDiaSemana = primerDiaMes.getDay();
    
        const diasMesActual = ultimoDiaMes.getDate();
    
        const calendario = [];
    
        let dia = 1;
    
        for (let i = 0; i < 6; i++) {
          const semana = [];
          for (let j = 0; j < 7; j++) {
            if ((i === 0 && j < primerDiaSemana) || dia > diasMesActual) {
              semana.push(<td className="td" key={`${i}-${j}`}>&nbsp;</td>);
            } else {
              const fecha = new Date(añoActual, mesActual, dia);
              const filfec = moment(fecha).format("YYYY-MM-DD")  
              const esFechaPasada =
            moment(fecha).format("YYYY-MM-DD") <
            moment(fechaActual).format("YYYY-MM-DD");

               const estiloDia = {
            backgroundColor:
              fechaSeleccionada &&
              moment(fechaSeleccionada).format("YYYY-MM-DD") === filfec
                ? "#736ced"
                : esFechaPasada
                ? "#f0f0f0" // Color gris claro para fechas pasadas
                : "#dcdefc",
            color: esFechaPasada
              ? "#999"
              : moment(fechaSeleccionada).format("YYYY-MM-DD") === filfec
              ? "#fff"
              : "#736ced",
            borderRadius: "10px",
            border: "solid 2px white",
          };
              
              const reservasDia = reservas.filter((r) => r.fecha === filfec && Number(r.id_prestador) === Number(profesional.id)).map((data)=>(
                data
              ));   
              
 

              const cantidadReservas = reservasDia.filter((x)=>Number(x.id_estado) !== 3 && Number(x.id_estado) !== 6).length;
              const cantidadReservasConfirmadas = reservasDia.filter((x)=>Number(x.id_estado) === 3).length;
              const cantidadReservasFinalizados = reservasDia.filter((x)=>Number(x.id_estado) === 6).length;
                semana.push(
                    <td className="td active" key={`${i}-${j}`} onClick={() => obtenerFecha(fecha)} style={estiloDia}>
                    <div>
                        <div>{dia}</div>
                        {reservasDia.length > 0  ? ( 
                        <div className='d-flex justify-content-evenly'>
                         {cantidadReservas > 0 &&                       
                        <div className=" circuloCeroItemsSm" >
                           <small>{cantidadReservas}</small> 
                        </div>
                        }
                        {cantidadReservasConfirmadas > 0 &&
                        <div className=" circuloCeroSm" >
                        <small>{cantidadReservasConfirmadas}</small>
                        </div>
                        }
                        {cantidadReservasFinalizados > 0 &&
                        <div className=" circuloCeroFn" >
                        <small>{cantidadReservasFinalizados}</small>
                        </div>
                        }
                        </div>
                        ) : (
                        <div >
                           {' '}
                        </div>
                        )}
                    </div>
                    </td>
                );
                
                // Incrementar 'dia' después de agregar el elemento al calendario
                dia++;
  
            }
          }
          calendario.push(<tr key={i}>{semana}</tr>);
          if (dia > diasMesActual) break;
        }
    
        return calendario;
      };
    
      const cambiarMes = (mes) => {
        if (mes < 0) {
          setMesActual(11);
          setAñoActual(añoActual - 1);
        } else if (mes > 11) {
          setMesActual(0);
          setAñoActual(añoActual + 1);
        } else {
          setMesActual(mes);
        }
      };
    
      const cambiarAño = (año) => {
        setAñoActual(año);
      };
    
      const meses = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ];
// <button className="btn btn-primary btn-sm"  onClick={() => cambiarAño(añoActual - 1)}>Ant. Año</button>
//<button className="btn btn-primary btn-sm"  onClick={() => cambiarAño(añoActual + 1)}>Sig. Año</button>
  return (
    <div>     
      <div className="d-flex justify-content-between"> 
      <div style={{
										background: '#736ced',
										color: '#fff',
										borderRadius: '5px',
										display: 'flex',
										alignItems: 'center',
										justifyContent:'center',
										fontSize: '1.5rem',
										width: '2rem',
										height: '2rem',
                    cursor: 'pointer'

									}}><BsIcons.BsChevronLeft onClick={() => cambiarMes(mesActual - 1)}/></div>      
        
        <small>{meses[mesActual]} {añoActual}</small>
        <div style={{
										background: '#736ced',
										color: '#fff',
										borderRadius: '5px',
										display: 'flex',
										alignItems: 'center',
										justifyContent:'center',
										fontSize: '1.5rem',
										width: '2rem',
										height: '2rem',
                    cursor: 'pointer'
									}}><BsIcons.BsChevronRight  onClick={() => cambiarMes(mesActual + 1)}/></div> 
 
                
      </div>
      
      <div className="justify-content-center estilo-x">
      <table className='mx-auto'>
        <thead>
          <tr>
            <th className="cl">Dom</th>
            <th className="cl">Lun</th>
            <th className="cl">Mar</th>
            <th className="cl">Mié</th>
            <th className="cl">Jue</th>
            <th className="cl">Vie</th>
            <th className="cl">Sáb</th>
          </tr>
        </thead>
        <tbody>{generarCalendario()}</tbody>
      </table><br/>
      <div style={{display:'flex',
                  justifyContent: 'center',
                  gap: '20px'
    }}>
        <div style={{
          backgroundColor: '#ffba57',
          color: '#fff',
          padding: '4px',
          borderRadius: '5px',
        }}>
          Requieren acción
        </div>
        <div style={{
          backgroundColor: '#3b82f6',
          color: '#fff',
          padding: '4px',
          borderRadius: '5px',
        }}>
          Confirmados
        </div>
        <div style={{
          backgroundColor: '#0acf97',
          color: '#fff',
          padding: '4px',
          borderRadius: '5px',
        }}>
          Finalizados
        </div>
      </div>
      </div>
    </div>
  );
}
export default Calendario