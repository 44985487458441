import React, { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as BsIcons from 'react-icons/bs';

const FormularioPaciente = ({ datos, handleChange }) => {
  const [view, setView] = useState(false);
  const handleClick = () => {
    setView(!view);
  };
  return (
    <div className="">
      <>
        <div className=" pt-1 mb-2" onClick={handleClick} style={{display:'flex',justifyContent: 'space-between', alignItems: 'center'}}>Datos    {!view ? <BsIcons.BsChevronRight/> : <BsIcons.BsChevronDown/> }  </div>
        {view && (
          <div className="pt-3">
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingActual"
                  label="Edad"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    name="edad"
                    value={datos && datos.edad === 0 ? "" : datos.edad}
                    onChange={handleChange}
                    placeholder="Edad"
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingLocalidad"
                  label="Estado civil *"
                  className="mb-3"
                >
                  <select
                    type="text"
                    name="estadoCivil"
                    className="form-control"
                    value={datos && datos.estadoCivil}
                    onChange={handleChange}
                  >
                    <option value="">Estado civil</option>
                    <option value="Casada">Casada</option>
                    <option value="Soltera">Soltera</option>
                  </select>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingActual"
                  label="Obra Social"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    name="obraSocial"
                    placeholder="Obra Social"
                    value={datos && datos.obraSocial}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingTotal"
                  label="Plan de Cobertura"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    name="planCobertura"
                    value={datos && datos.planCobertura}
                    placeholder="Plan de Cobertura"
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingActual"
                  label="Tipo de Cobertura"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    name="tipoCobertura"
                    placeholder="Tipo de Cobertura"
                    value={datos && datos.tipoCobertura}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingTotal"
                  label="Número de Socio"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    name="numeroSocio"
                    value={datos && datos.numeroSocio}
                    onChange={handleChange}
                    placeholder="Número de Socio"
                  />
                </FloatingLabel>
              </Col>
            </Row>
          </div>
        )}
      </>
    </div>
  );
};

export default FormularioPaciente;
