import React, { createContext, useState, useEffect } from "react";
import {apiConfig} from "../services/apiConfig";
import axios from "axios";

export const AuthContext = createContext();

// Define the base URL
const Axios = axios.create({
  baseURL: apiConfig + "auth/",
});

const AuthProvider = ({ children }) => {
  const [showLogin, setShowLogin] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [theUser, setTheUser] = useState([]);

  useEffect(() => {
    isLoggedIn();
  }, []);

  const toggleNav = () => {
    setShowLogin(!showLogin);
  };
  const registerUser = async (user) => {
    try {
      const register = await Axios.post("register.php", {
        name: user.name,
        lastname: user.lastname,
        phone: user.phone,
        email: user.email,
        password: user.password,
      });
      return register.data;
    } catch (error) {
      console.error("Error al registrar al usuario:", error);

      return null;
    }
  };
  const logoutUser = () => {
    localStorage.clear();

    setIsAuth(false);
  };

  const loginUser = async (user) => {
    const login = await Axios.post("login.php", {
      email: user.email,
      password: user.password,
    });

    setTheUser(login.data.nombre);
    localStorage.setItem('user', login.data.nombre);
    return login.data;
  };

  const isLoggedIn = async () => {
    const loginToken = localStorage.getItem("loginToken");
    if (loginToken) {
      setIsAuth(true);
    }
  };

  const contextValue = {
    rootState: { showLogin, isAuth, theUser },
    toggleNav,
    isLoggedIn,
    loginUser,
    logoutUser,
    registerUser,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
