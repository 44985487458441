"use client";
import React from 'react';


const LicenciaRenovacion = ({}) => {
	return (
		<div>
 			LicenciaRenovacion works!
 		</div>
	);
};



export default LicenciaRenovacion;