"use client";
import React from 'react';
import './Paciente.css';
import { NavLink, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";


const Paciente = ({}) => {
	let { id } = useParams();
	const bytes = CryptoJS.AES.decrypt(decodeURIComponent(id), "clave_secreta");
	const info = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	return (
		<div className='paciente'>
 			
 		</div>
	);
};



export default Paciente;