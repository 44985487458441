import React, { useState, useEffect } from "react";
import Moment from "moment";
import "moment/locale/es";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import {apiConfig} from "../../services/apiConfig";
import { Col, Container, Row, Card } from "react-bootstrap";
import Navb from "../../components/navbar/Navb";
import useAuth from "../../auth/useAuth";
import TurnosEstados from "./TurnosEstados/TurnosEstados";
import TurnosPagados from "./TurnosPagados/TurnosPagados";


export default function Home() {
	const auth = useAuth();
	const user = [auth.rootState.theUser][0];
	const [currentDate, setCurrentDate] = useState(Moment()); // Estado para almacenar la fecha actual
	const [turnos, setTurnos] = useState([]);
	const handleNextDate = () => {
	  const nextDate = Moment(currentDate).add(1, 'day');
	  setCurrentDate(nextDate);
	};
  
	const handlePreviousDate = () => {
	  const previousDate = Moment(currentDate).subtract(1, 'day');
	  setCurrentDate(previousDate);
	};
  
	const daysOfWeek = [];
	const dates = [];
	const dia = []
  
	for (let i = 0; i < 5; i++) {
	  const day = Moment(currentDate).add(i, 'days');
	  const dayOfWeek = day.format('ddd').charAt(0).toUpperCase() + day.format('ddd').slice(1).replace('.', ''); // 'ddd' muestra el día de la semana abreviado, como "lun", "mar", etc.
	  const date = day.format('YYYY-MM-DD'); // 'D' muestra el día del mes
	  const dias = day.format('D'); 
	  daysOfWeek.push(dayOfWeek);
	  dates.push(date);
	  dia.push(dias);
	}
	useEffect(() => {
		const getData = () => {
		  axios
			.get(apiConfig + 'turnos/list/cantidad.php?id=' + Number(user.id_business_office))
			.then((getData) => {
			  setTurnos(getData.data);
			});
		}
		getData();

	  }, [user.id_business_office]);

  return (
	<>
		<Navb />
		<Container style={{paddingTop: '70px'}}>
			<Card>
				<Card.Body>
					<div className="text-start">
						<div style={{ fontSize: "1.5rem" }}>Hola,  {user.name}</div>
					</div>
				</Card.Body>
			</Card>

			<Row className="pt-3">
				<Col md={6} sm={12} className="mb-3">
					<Card>						
						<Card.Body>
							<div style={{
								display: 'flex',
								justifyContent: 'center',
								gap: 20,
								alignItems: 'center'
						}}>
							<BsIcons.BsCalendar style={{ fontSize: "1.5rem", color: '#736ced' }} /><span style={{ fontSize: "1.5rem" }}>Estado de reservas</span>	
							</div>
							<div className="pt-4 mb-2" style={{
								display:'flex',
								justifyContent: 'space-between',
								}}>
								<div style={{
										background: '#736ced',
										color: '#fff',
										borderRadius: '5px',
										display: 'flex',
										alignItems: 'center',
										justifyContent:'center',
										fontSize: '1.5rem',
										width: '2rem',
										height: '2rem'
									}}><BsIcons.BsChevronLeft onClick={handlePreviousDate}/></div>
									<div style={{
										background: '#736ced',
										color: '#fff',
										borderRadius: '5px',
										display: 'flex',
										alignItems: 'center',
										justifyContent:'center',
										fontSize: '1.5rem',
										width: '2rem',
										height: '2rem'
									}}><BsIcons.BsChevronRight  onClick={handleNextDate}/>
									</div>
							</div>
							<Row style={{justifyContent: 'center'}}>							
								{daysOfWeek.map((day, index) => (
								<Col  key={index} >
									<div className="text-center">
										<TurnosEstados day={dates[index]} turnos={turnos} currentDate={currentDate}/>
									</div>
								</Col>
								))}

							</Row>
							<div className="text-muted pt-3 text-end"><small><BsIcons.BsFillCircleFill style={{color:'#00b746'}}/> Confirmado</small>   </div>
						</Card.Body>
					</Card>
				</Col>
				<Col md={6} sm={12} className="mb-3">
					<Card>						
						<Card.Body>
						<div style={{
								display: 'flex',
								justifyContent: 'center',
								gap: 20,
								alignItems: 'center'
								
						}}>
							<BsIcons.BsCashCoin style={{ fontSize: "1.5rem", color: '#736ced' }} /><span style={{ fontSize: "1.5rem" }}>Estado de pagos</span>	
							</div>
							<div className="pt-4 mb-2" style={{
								display:'flex',
								justifyContent: 'space-between',
								
								}}>
								<div style={{
										background: '#736ced',
										color: '#fff',
										borderRadius: '5px',
										display: 'flex',
										alignItems: 'center',
										justifyContent:'center',
										fontSize: '1.5rem',
										width: '2rem',
										height: '2rem'
									}}><BsIcons.BsChevronLeft onClick={handlePreviousDate}/></div>
									<div style={{
										background: '#736ced',
										color: '#fff',
										borderRadius: '5px',
										display: 'flex',
										alignItems: 'center',
										justifyContent:'center',
										fontSize: '1.5rem',
										width: '2rem',
										height: '2rem'
									}}><BsIcons.BsChevronRight  onClick={handleNextDate}/>
									</div>
							</div>
							<Row style={{justifyContent: 'center'}}>							
								{daysOfWeek.map((day, index) => (
								<Col  key={index} >
									
										<TurnosPagados day={dates[index]} turnos={turnos} currentDate={currentDate}/>									
							
								</Col>
								))}

							</Row>
							<div className="text-muted pt-3 text-end"><small><BsIcons.BsFillCircleFill style={{color:'#00b746'}}/> Pagado </small></div>
						</Card.Body>
					</Card>
				</Col>
				<Col md={6} sm={12} className="mb-3">
					<Card>						
						<Card.Body>
						<div style={{
								display: 'flex',
								justifyContent: 'center',
								gap: 20,
								alignItems: 'center'
								
						}}>
							<BsIcons.BsCashCoin style={{ fontSize: "1.5rem", color: '#736ced' }} /><span style={{ fontSize: "1.5rem" }}>Medios de pago</span>	
						</div>
							
						</Card.Body>
					</Card>
				</Col>
				<Col md={6} sm={12} className="mb-3">
					<Card>						
						<Card.Body>
						<div style={{
								display: 'flex',
								justifyContent: 'center',
								gap: 20,
								alignItems: 'center'
								
						}}>
							<BsIcons.BsCalendar2Check style={{ fontSize: "1.5rem", color: '#736ced' }} /><span style={{ fontSize: "1.5rem" }}>Fechas importantes</span>	
						</div>
							
						</Card.Body>
					</Card>
				</Col>					
			</Row>

		</Container>
	</>
		)
	
} 

