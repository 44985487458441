import "moment/locale/es";
import {   Container } from "react-bootstrap";
import moment from "moment";
import * as BsIcons from "react-icons/bs";
import { useData } from "../../DataContext/DataContext";


const DetalleVenta = ({datoSeleccionado}) => {
	const { pagos } =
    useData();
	const imprimirPantalla = () => {
		window.print();
	  }
	return (
		<div>
 			   {datoSeleccionado && (
                <Container>
                  <div
                    className="mb-3"
                    style={{
                    //  boxShadow: "0px 4px 15px rgba(79,79,79,0.1)",
                      fontSize: "0.8rem",
                      padding: "15px",
                      margin: "5px",
                      borderRadius: "2px",
                    }}
                    id="divAImprimir"
                  > 
                  <div className="text-end h1">
                    <BsIcons.BsPrinter onClick={imprimirPantalla}/>
                  </div>
                    <div className="text-center pt-5">
                      <div>
                        <h3>{datoSeleccionado.tratamiento}</h3>
                        <small>Total pagado</small>
                        <br />
                        <h1 className="display-3" style={{ color: "#736CED" }}>
                          <strong>
                            {pagos && pagos.filter(
                        (x) =>
                          Number(x.id_turnos) === Number(datoSeleccionado.id)).length > 0 ?
                      pagos.filter(
                        (x) =>
                          Number(x.id_turnos) === Number(datoSeleccionado.id)
                      ).reduce((suma, pago) => suma + Number(pago.monto), 0).toLocaleString("es-AR", {
                              style: "currency",
                              currency: "ARS",
                              minimumFractionDigits: 2,
                            }) 
                            : Number(0).toLocaleString("es-AR", {
                              style: "currency",
                              currency: "ARS",
                              minimumFractionDigits: 2,})}
                          </strong>
                        </h1>
                        <strong>
                                <h5>
                                Saldo a pagar {(Number(datoSeleccionado.precio) - pagos.filter(
                        (x) =>
                          Number(x.id_turnos) === Number(datoSeleccionado.id)
                      ).reduce((suma, pago) => suma + Number(pago.monto), 0)).toLocaleString("es-AR", {
                        style: "currency",
                        currency: "ARS",
                        minimumFractionDigits: 2,
                      }) }
                                </h5>
                        </strong>
         
                      </div>
                    </div>

                    <div
                      className="pt-2 mb-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="payTitulo">{datoSeleccionado.nombre+','+datoSeleccionado.apellido}</div>
                      <div className="payTitulo">
                        {" "}
                        {moment().format("DD-MM-YYYY")}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <div className="text-start">
                          Código
                          <br />
                          <strong>{datoSeleccionado.codigo}</strong>
                        </div>
                        <div>
                        {datoSeleccionado.tratamiento}
                          <br />
                         
                          {moment(datoSeleccionado.fecha).format("DD-MM-YYYY")}{" "}
                          {datoSeleccionado.hora}
                        </div>
                     
                      </div>
                      <div>
                        {Number(datoSeleccionado.precio).toLocaleString("es-AR", {
                          style: "currency",
                          currency: "ARS",
                          minimumFractionDigits: 2,
                        })}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    ></div>
                    <br />
                    {pagos &&
                      pagos.filter(
                        (x) =>
                          Number(x.id_turnos) === Number(datoSeleccionado.id)
                      ).length > 0 && (
                        <>
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col" className="text-start">
                                  Nº. Transacción
                                </th>
                                <th scope="col" className="text-start">
                                  Fecha de pagó
                                </th>
                                <th scope="col" className="text-start">
                                  Medio
                                </th>
                                <th scope="col" className="text-end">
                                  Importe
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {pagos
                                .filter(
                                  (x) =>
                                    Number(x.id_turnos) ===
                                    Number(datoSeleccionado.id)
                                )
                                .map((data, i) => {
                                  return (
                                    <tr key={i} >
                                      <td className="text-start">
                                        <small>
                                          {data.transaccion ? data.transaccion : data.transaccionMp }
                                        </small>
                                      </td>
                                      <td className="text-start">
                                        <small>
                                          {moment(data.fecha_pago).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </small>
                                      </td>
                                      <td className="text-start">
                                        <small>
                                          {Number(data.id_medio_pago) === 1
                                            ? "Efectivo"
                                            : "Mercado Pago"}
                                        </small>
                                      </td>
                                      <td className="text-end">
                                        <small>
                                          {Number(data.monto).toLocaleString(
                                            "es-AR",
                                            {
                                              style: "currency",
                                              currency: "ARS",
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </small>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      )}
                  </div>
                </Container>
              )}
 		</div>
	);
};

DetalleVenta.propTypes = {};

export default DetalleVenta;