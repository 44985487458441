import { NavLink } from 'react-router-dom';
import axios from 'axios';
import React, {useState,  useEffect} from 'react';
import {apiConfig} from "../../services/apiConfig";
import * as BsIcons from 'react-icons/bs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './styles/Config.css';
import { DotWave } from '@uiball/loaders'
import {  useData } from '../../DataContext/DataContext';
import Spinner from 'react-bootstrap/Spinner';


function Config() {

  const { usuarios,
        locales,
        prestadores,  
        clientes, 
        servicios,
        integraciones, 
        user, } = useData(); 


 const [website, setWebsite] = useState([]);
 

 const [Loading,setLoading]=useState(false);
 const firstFiveItems = usuarios && usuarios.length < 1 ? usuarios.slice(0, 5) : usuarios;
 const totalItemCount = usuarios ? usuarios.length : 0;
 const firstFiveLocal = locales && locales.length < 1 ? locales.slice(0, 5) : locales;
 const totalLocalCount = locales ?  locales.length : '';
 const firstFivePres = prestadores && prestadores.length > 0 ? prestadores.slice(0, 5) : prestadores;
 const totalPresCount = prestadores ? prestadores.length : 0;
 const firstFiveClientes = clientes && clientes.length > 0 ? clientes.slice(0, 5) : clientes;
 const totalClientesCount = clientes ? clientes.length : 0;
 const firstFiveServicios = servicios && servicios.length > 0 ? servicios.slice(0, 5) : servicios;
 const totalServiciosCount = servicios ? servicios.length : 0;
 
 const colores = [ '#ffc700', '#3e96fe','#eb3f69','#50cd89','#736ced'];

 useEffect(() => {      
if(user && user.id_business_unit){
  axios.get(apiConfig + 'web_cliente/list/index.php?id=' + Number(user.id_business_unit))
    .then((getData) => {
      let  data  = getData.data;
      if(data.length > 0){
        setWebsite(data)      					
      }
    });
    setLoading(true)
}


 },[user]);

  return (
      <div className='container' >{Loading ? <>
        <div className='text-start'>
          <NavLink exact to={'/calendario'} >
            <h1 className='display-1'><BsIcons.BsArrowLeftShort className="app-color" /></h1>
          </NavLink>
        </div>
        <div className='card shadow mb-3' style={{ background: "#736CED" }}>
            <div className='card-body'>
              <div className='text-center text-white'>
                  Panel de administración
              </div>
            </div>  
        </div>
        <div className='row '>
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/profile'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{usuarios ? usuarios.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Usuarios</span>
                  </div>                  
                  <div  className="symbol-group symbol-hover flex-nowrap pt-3">
                    {usuarios ? firstFiveItems.map((data,index)=>(
                    <OverlayTrigger  key={index} overlay={<Tooltip id="tooltip-disabled">{data.name+', '+data.last_name}</Tooltip>}>
                      <div className="symbol symbol-35px symbol-circle" >
                        <img alt={data.name} src={apiConfig+'profile/image/'+data.avatar_url} />
                      </div>
                    </OverlayTrigger>
                         )) : 
                         <DotWave 
                            size={47}
                            speed={1} 
                            color="black" 
                            />
                    }
                      {totalItemCount > 5 && (
                        <div  className="symbol symbol-35px symbol-circle" >
                           +{totalItemCount - 5}
                        </div>         
                      )}        
                  </div>
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/locales'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{locales ? locales.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Locales</span>
                  </div>                  
                  <div  className="symbol-group symbol-hover flex-nowrap pt-3">
                    {locales ? firstFiveLocal.map((data,index)=>(
                    <OverlayTrigger  key={index} overlay={<Tooltip id="tooltip-disabled">{data.name}</Tooltip>}>
                      <div className="symbol symbol-35px symbol-circle" >
                      <div style={{width:'35px', height: '35px',background: colores[index % colores.length],color:'white',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {index+1}
                        </div> 
                      </div>
                    </OverlayTrigger>
                         )) : 
                         <DotWave 
                            size={47}
                            speed={1} 
                            color="black" 
                            />
                    }
                      {totalLocalCount > 5 && (
                        <div  className="symbol symbol-35px symbol-circle" >
                           +{totalLocalCount - 5}
                        </div>         
                      )}        
                  </div>
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>          
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/prestadores'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{prestadores ? prestadores.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Prestadores</span>
                  </div>                  
                  <div  className="symbol-group symbol-hover flex-nowrap pt-3">
                    {prestadores ? firstFivePres.map((data,index)=>(
                    <OverlayTrigger  key={index} overlay={<Tooltip id="tooltip-disabled">{data.nombre+', '+data.apellido}</Tooltip>}>
                      <div className="symbol symbol-35px symbol-circle" >
                      <div style={{width:'35px', height: '35px',background: colores[index % colores.length],color:'white',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {data.nombre.substr(0,1)}{data.apellido.substr(0,1)} 
                        </div> 
                      </div>
                    </OverlayTrigger>
                         )) : 
                         <DotWave 
                            size={47}
                            speed={1} 
                            color="black" 
                            />
                    }
                      {totalPresCount > 5 && (
                        <div  className="symbol symbol-35px symbol-circle" >
                           +{totalPresCount - 5}
                        </div>         
                      )}        
                  </div>
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>
          <div className='col-md-6 col-sm-12 mb-3'>
          <NavLink exact to={'/config/categoria'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{servicios ? servicios.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Servicios</span>
                  </div>                  
                  <div  className="symbol-group symbol-hover flex-nowrap pt-3">
                    {servicios ? firstFiveServicios.map((data,index)=>(
                    <OverlayTrigger  key={index} overlay={<Tooltip id="tooltip-disabled">{index+1}</Tooltip>}>
                      <div className="symbol symbol-35px symbol-circle" >
                        <div style={{width:'35px', height: '35px',background: colores[index % colores.length],color:'white',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {index+1} 
                        </div>                        
                      </div>
                    </OverlayTrigger>
                         )) : 
                         <DotWave 
                            size={47}
                            speed={1} 
                            color="black" 
                            />
                    }
                      {totalServiciosCount > 5 && (
                        <div  className="symbol symbol-35px symbol-circle" >
                          <div style={{width:'35px', height: '35px',background: '#f9f9f9', color:'#c7c6cd',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                           +{totalServiciosCount - 5}
                           </div>
                        </div>         
                      )}        
                  </div>
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/customer'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{clientes ? clientes.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Clientes</span>
                  </div>                  
                  <div  className="symbol-group symbol-hover flex-nowrap pt-3">
                    {clientes ? firstFiveClientes.map((data,index)=>(
                    <OverlayTrigger  key={index} overlay={<Tooltip id="tooltip-disabled">{data.nombre+', '+data.apellido}</Tooltip>}>
                      <div className="symbol symbol-35px symbol-circle" >
                        <div style={{width:'35px', height: '35px',background: colores[index % colores.length],color:'white',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {data.nombre.substr(0,1)}{data.apellido.substr(0,1)} 
                        </div>                        
                      </div>
                    </OverlayTrigger>
                         )) : 
                         <DotWave 
                            size={47}
                            speed={1} 
                            color="black" 
                            />
                    }
                      {totalClientesCount > 5 && (
                        <div  className="symbol symbol-35px symbol-circle" >
                          <div style={{width:'35px', height: '35px',background: 'grey', color:'white',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                           +{totalClientesCount - 5}
                           </div>
                        </div>         
                      )}        
                  </div>
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/webUpdate'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{website ? website.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Tienda web</span>
                  </div>                  
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/medios'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{integraciones ? integraciones.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Medios de pago electrónicos</span>{integraciones.length && 
                      <div className='pt-1 text-primary'>
                        Mercado pago
                      </div>
                      }
                  </div>                  
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/mensajeria'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                     
                      <span style={{color:'#474747'}}>Mensajeria</span>
                  </div>                  
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>     
        </div>
        </>
        :
        <>
        <Spinner/>
        </>
                    }
      </div>      
  )
}

export default Config

