import { useState } from "react";
import * as BsIcons from "react-icons/bs";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { apiConfig } from "../../services/apiConfig";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useData } from "../../DataContext/DataContext";

function Locales() {
  const { locales, localesHorarios, user, getDataAll } = useData();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [editar, setEditar] = useState([]);

  const [dias, setDias] = useState([
    {
      dia_semana: "Lunes",
      horario_apertura: "08:00",
      horario_cierre: "22:00",
      estado: 1,
    },
    {
      dia_semana: "Martes",
      horario_apertura: "08:00",
      horario_cierre: "22:00",
      estado: 1,
    },
    {
      dia_semana: "Miercoles",
      horario_apertura: "08:00",
      horario_cierre: "22:00",
      estado: 1,
    },
    {
      dia_semana: "Jueves",
      horario_apertura: "08:00",
      horario_cierre: "22:00",
      estado: 1,
    },
    {
      dia_semana: "Viernes",
      horario_apertura: "08:00",
      horario_cierre: "22:00",
      estado: 1,
    },
    {
      dia_semana: "Sabado",
      horario_apertura: "08:00",
      horario_cierre: "22:00",
      estado: 1,
    },
    {
      dia_semana: "Domingo",
      horario_apertura: "08:00",
      horario_cierre: "22:00",
      estado: 0,
    },
  ]);
  const handleDiaChange = (index, field, value) => {
    const updatedDias = [...dias];
    updatedDias[index][field] = value;
    setDias(updatedDias);
  };

  const [registro, setRegistro] = useState({
    name: "",
    abbreviation: "",
    address: "",
    phone: "",
    email: "",
    description: "",
    estado: "",
    id_business_unit: user.id_business_unit,
    dias: dias,
  });

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(apiConfig + `business/add/index.php`, registro)
      .then((res) => {
        let { data } = res;
        if (Number(data.success) === 1) {
          getDataAll();
          setShow(false);
        }
      })
      .catch(function (res) {});
  }
  function handleSubmit1(e) {
    e.preventDefault();
    axios
      .post(apiConfig + `business/put/editar.php`, editar)
      .then((res) => {
        let { data } = res;
        if (Number(data.success) === 1) {
          getDataAll();
          setShow1(false);
        }
      })
      .catch(function (res) {
        console.log(res);
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegistro((prevRegistro) => ({
      ...prevRegistro,
      [name]: value,
    }));
  };

  const handleChange1 = (e) => {
    setEditar({ ...editar, [e.target.name]: e.target.value });
  };

  function onErase(id) {
    axios.get(apiConfig + `business/delete/delete.php?id=${id}`).then(() => {
      getDataAll();
    });
  }

  function onDelete(id) {
    axios.get(apiConfig + `business/delete/index.php?id=${id}`).then(() => {
      getDataAll();
    });
  }

  function onActive(id) {
    axios.get(apiConfig + `business/delete/active.php?id=${id}`).then(() => {
      getDataAll();
    });
  }
  function onActiveDay(id) {
    axios.get(apiConfig + `business/day/active.php?id=${id}`).then(() => {
      getDataAll();
    });
  }
  function offActiveDay(id) {
    axios.get(apiConfig + `business/day/desactive.php?id=${id}`).then(() => {
      getDataAll();
    });
  }
  return (
    <div className="container">
      <div className="text-start">
        <NavLink exact to={"/config"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#736CED" }}>
        <div className="card-body">
          <div className="text-center text-white">Locales</div>
        </div>
      </div>
      <div className="text-end  mb-5">
        <h1>
          <BsIcons.BsPlusCircleFill
            onClick={handleShow}
            style={{ color: "#736CED" }}
          />
        </h1>
      </div>
      <Modal show={show} size="lg" fullscreen={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <h1>Agregar Local</h1>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={6}>
                <FloatingLabel
                  controlId={"nombre"}
                  className="mb-3"
                  label="Nombre  *"
                >
                  <Form.Control
                    type="text"
                    name="name"
                    value={registro.name}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId={"Abreviatura"}
                  className="mb-3"
                  label="Abreviatura  *"
                >
                  <Form.Control
                    type="text"
                    name="abbreviation"
                    value={registro.abbreviation}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId={"Dirección"}
                  className="mb-3"
                  label="Dirección  *"
                >
                  <Form.Control
                    type="text"
                    name="address"
                    value={registro.address}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>

              <Col md={6}>
                <FloatingLabel
                  controlId={"Teléfono"}
                  className="mb-3"
                  label="Teléfono  *"
                >
                  <Form.Control
                    type="text"
                    name="phone"
                    value={registro.phone}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId={"Email"}
                  className="mb-3"
                  label="Email  *"
                >
                  <Form.Control
                    type="email"
                    name="email"
                    value={registro.email}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId={"Descripción"}
                  className="mb-3"
                  label="Descripción  *"
                >
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={registro.description}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
            </Row>

            {dias.map((dia, index) => (
              <Row key={index} className="mb-1">
                <Col>
                  <FloatingLabel
                    controlId={index + "dia"}
                    className="mb-3"
                    label="Dia"
                  >
                    <Form.Control
                      type="tex"
                      name="fecha"
                      defaultValue={dia.dia_semana}
                      readOnly
                    />
                  </FloatingLabel>
                </Col>

                <Col>
                  <FloatingLabel
                    controlId={index + "apertura"}
                    className="mb-3"
                    label="Horario apertura  *"
                  >
                    <Form.Control
                      type="time"
                      name="horario_apertura"
                      value={dia.horario_apertura}
                      onChange={(e) =>
                        handleDiaChange(
                          index,
                          "horario_apertura",
                          e.target.value
                        )
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel
                    controlId={index + "cierre"}
                    className="mb-3"
                    label="Horario cierre  *"
                  >
                    <Form.Control
                      type="time"
                      name="horario_cierre"
                      value={dia.horario_cierre}
                      onChange={(e) =>
                        handleDiaChange(index, "horario_cierre", e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <div className="pt-3">
                    <Form.Check
                      type="switch"
                      id={index + "custom-switch"}
                      value={dia.estado}
                      onChange={(e) =>
                        handleDiaChange(index, "estado", e.target.checked)
                      }
                    />
                  </div>
                </Col>
              </Row>
            ))}

            <div className="d-grid gap-2 pt-5">
              <button
                onClick={handleSubmit}
                className="btn app-button  text-white shadow"
              >
                Guardar
              </button>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      {locales &&
        locales.map((datos, index) => (
          <div key={index}>
            {Number(datos.estado) === 1 ? (
              <>
                <div className="card shadow mb-3 ">
                  <div className="card-body">
                    <div className="row ">
                      <div className="col-1 align-self-center h3">
                        <BsIcons.BsFillRecordFill
                          style={{ color: "#30ff24" }}
                        />
                      </div>
                      <div className="col-9 align-self-center text-start">
                        {datos.name}
                      </div>
                 
                      <div className="col-2 ">
                        <div className="row">
                          {Number(datos.estado) === 1 ? (
                            <div
                              className="col-6 text-center align-self-center"
                              onClick={() => onDelete(datos.id)}
                            >
                              <h3>
                                <BsIcons.BsToggleOn />
                              </h3>
                            </div>
                          ) : (
                            <div
                              className="col-6 text-center align-self-center"
                              onClick={() => onActive(datos.id)}
                            >
                              <h3>
                                <BsIcons.BsToggleOff />
                              </h3>
                            </div>
                          )}
                          <div className="col-6 text-center align-self-center">
                            <div
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                              onClick={() => setEditar(datos)}
                            >
                              <div className="">
                                <h3>
                                  <BsIcons.BsThreeDotsVertical
                                    onClick={handleShow1}
                                  />
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal show={show1} size="lg" onHide={handleClose1}>
                  <Modal.Header closeButton>
                    <h1>Editar Local</h1>
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row>
                        <Col md={6}>
                          <FloatingLabel
                            controlId={"nombre1"}
                            className="mb-3"
                            label="Nombre  *"
                          >
                            <Form.Control
                              type="text"
                              name="name"
                              value={editar && editar.name}
                              onChange={handleChange1}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md={6}>
                          <FloatingLabel
                            controlId={"Abreviatura1"}
                            className="mb-3"
                            label="Abreviatura  *"
                          >
                            <Form.Control
                              type="text"
                              name="abbreviation"
                              value={editar && editar.abbreviation}
                              onChange={handleChange1}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md={6}>
                          <FloatingLabel
                            controlId={"Dirección1"}
                            className="mb-3"
                            label="Dirección  *"
                          >
                            <Form.Control
                              type="text"
                              name="address"
                              value={editar && editar.address}
                              onChange={handleChange1}
                            />
                          </FloatingLabel>
                        </Col>

                        <Col md={6}>
                          <FloatingLabel
                            controlId={"Teléfono1"}
                            className="mb-3"
                            label="Teléfono  *"
                          >
                            <Form.Control
                              type="text"
                              name="phone"
                              value={editar && editar.phone}
                              onChange={handleChange1}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md={6}>
                          <FloatingLabel
                            controlId={"Email1"}
                            className="mb-3"
                            label="Email  *"
                          >
                            <Form.Control
                              type="email"
                              name="email"
                              value={editar && editar.email}
                              onChange={handleChange1}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col md={6}>
                          <FloatingLabel
                            controlId={"Descripción1"}
                            className="mb-3"
                            label="Descripción  *"
                          >
                            <Form.Control
                              as="textarea"
                              name="description"
                              value={editar && editar.description1}
                              onChange={handleChange1}
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>

                      {localesHorarios
                        .filter(
                          (x) =>
                            Number(x.id_bussines_office) === Number(editar.id)
                        )
                        .map((diax, index) => (
                          <Row key={index} className="mb-1">
                            <Col>
                              <FloatingLabel
                                controlId={index + "dia1"}
                                className="mb-3"
                                label="Dia"
                              >
                                <Form.Control
                                  type="tex"
                                  name="dias"
                                  defaultValue={diax.dias}
                                  readOnly
                                />
                              </FloatingLabel>
                            </Col>

                            <Col>
                              <FloatingLabel
                                controlId={index + "apertura1"}
                                className="mb-3"
                                label="Horario apertura  *"
                              >
                                <Form.Control
                                  type="time"
                                  name="apertura"
                                  value={diax.apertura}
                                />
                              </FloatingLabel>
                            </Col>
                            <Col>
                              <FloatingLabel
                                controlId={index + "cierre1"}
                                className="mb-3"
                                label="Horario cierre  *"
                              >
                                <Form.Control
                                  type="time"
                                  name="cierre"
                                  value={diax.cierre}
                                />
                              </FloatingLabel>
                            </Col>
                            <Col>
                              <div className="pt-3">
                                {Number(diax.estado) === 1 ? (
                                  <BsIcons.BsToggleOn
                                    style={{
                                      fontSize: "2rem",
                                      color: "#736CED",
                                    }}
                                    onClick={() => offActiveDay(diax.id)}
                                  />
                                ) : (
                                  <BsIcons.BsToggleOff
                                    style={{
                                      fontSize: "2rem",
                                      color: "#736CED",
                                    }}
                                    onClick={() => onActiveDay(diax.id)}
                                  />
                                )}
                              </div>
                            </Col>
                          </Row>
                        ))}

                      <div className="d-grid gap-2 pt-5">
                        <button
                          onClick={handleSubmit1}
                          className="btn app-button  text-white shadow"
                        >
                          Guardar
                        </button>
                      </div>
                    </Container>
                  </Modal.Body>
                </Modal>
              </>
            ) : (
              <div className="card shadow mb-3 ">
                <div className="card-body">
                  <div className="row text-muted">
                    <div className="col-1 align-self-center h3">
                      <BsIcons.BsFillRecordFill style={{ color: "#f80f00" }} />
                    </div>
                    <div className="col-3 align-self-center">{datos.name}</div>
                    <div className="col-6 align-self-center">
                      {datos.descripcion}
                    </div>
                    <div className="col-2 align-self-center">
                      <div className="row">
                        {Number(datos.estado) === 1 ? (
                          <div
                            className="col-6 text-center align-self-center"
                            onClick={() => onDelete(datos.id)}
                          >
                            <h3>
                              <BsIcons.BsToggleOn />
                            </h3>
                          </div>
                        ) : (
                          <div
                            className="col-6 text-center align-self-center"
                            onClick={() => onActive(datos.id)}
                          >
                            <h3>
                              <BsIcons.BsToggleOff />
                            </h3>
                          </div>
                        )}
                        <div className="col-6 text-center align-self-center">
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Eliminar"
                            onClick={() => onErase(datos.id)}
                          >
                            <h3>
                              <BsIcons.BsTrash />
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

export default Locales;
