import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import axios from "axios";
import {apiConfig} from "../../../services/apiConfig";
import * as BsIcons from "react-icons/bs";
import { ListGroup } from 'react-bootstrap';
import CustomerUpdate from '../../customer/CustomerUpdate/CustomerUpdate';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Imagen from "./Imagen";
import moment from "moment";
import DetalleVenta from "../../../components/DetalleVenta/DetalleVenta";

const HistorialCliente = (props) => {
	const valor = props.cliente;
	const [cliente,setCliente] = useState([]);
	const [historial,setHistorial] = useState(null)
	const [datoSeleccionado, setDatoSeleccionado]= useState([]);
	const [show, setShow] = useState(false);
	const [show1, setShow1] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleClose1 = () => setShow1(false);
	

	useEffect(() => {
		setCliente(valor)
		
	}, [valor]);
	

	useEffect(() => {
		const getTurno=()=>{
			axios
			.get(apiConfig + 'turnos/list/individual.php?id=' + Number(valor.id_cliente))
			.then((getData) => {
			  setHistorial(getData.data);
			  
			});
		}
		getTurno();
	}, [valor]);

	const handleClick = () =>{
		props.buscarPrestador()
		props.filtro('')
		props.cleanCliente()
		props.handleViewMobil()
	  }
	const actualizaInfo=(e)=>{
		setCliente(...[e])
		setShow(false);
	}
    const handleDetalleClick = (e) => {
		setDatoSeleccionado(e)
		setShow1(true)
	}

	return <Container>
			<Row className="pt-3">
				<Col sm={12} md={12}>
					<Card >
						<Card.Body>
							<div style={{
								fontSize: '1.5rem',
								display: 'flex',
								alignItems: 'center',
								gap: '20px'

								}}>
							<BsIcons.BsArrowLeftShort  style={{fontSize:'2rem'}} onClick={handleClick}/>
								Información de cliente
							</div>
						</Card.Body>						
					</Card><br/>
					<Card>
						<Row>
							<Col sm={12} md={12}>
								<div className="container text-center pt-5">
									<div className="" >
									<Imagen cliente={cliente} />
									
									</div>
									<div className="pt-3">
										<strong>{cliente.nombre+', '+cliente.apellido}</strong>
									</div>
									<Row>
										<Col>
											<div>
												<strong>Reservas</strong><br/>{historial ? historial.length : '0'}
											</div>
										</Col>
										<Col style={{borderLeft: 'solid 1px #ededed'}}>
											<div>
											<strong>Última</strong><br/>{historial && historial.length > 0 ? moment(historial[0].fecha).format("DD-MM-YYYY") : '-'}
											</div>
										</Col>										
									</Row><br/>
								</div>
							</Col>

						</Row>
						<Row>
						<div className="text-center pt-3">
									<h3>Datos personales</h3>
								</div>
						<Col sm={12} md={6} className="text-start" style={{borderLeft: 'solid 1px #ededed'}}>
								<ul>
									<li><strong>Nombre</strong><br/>{cliente.nombre+', '+cliente.apellido}</li>
									<li><strong>Teléfono</strong><br/>{cliente.telefono ? cliente.telefono : 'No registrado'}</li>
									<li><strong>Correo electrónico</strong><br/>{cliente.email ? cliente.email : 'No registrado'}</li>
									<li><strong>Fecha nacimiento</strong><br/>{cliente.fecha_nac ? cliente.fecha_nac : 'No registrado'}</li>
								</ul>
							</Col>
							<Col sm={12} md={6} className="text-start" style={{borderLeft: 'solid 1px #ededed'}}>					
								<ul>
				    				<li><strong>Dirección</strong><br/>{cliente.direccion ? cliente.direccion : 'No registrado'}</li>
									<li><strong>Ciudad</strong><br/>{cliente.ciudad ? cliente.ciudad : 'No registrado'}</li>
									<li><strong>Provincia</strong><br/>{cliente.provincia ? cliente.provincia : 'No registrado'}</li>
									<li><strong>Codígo Postal</strong><br/>{cliente.codigo_postal ? cliente.codigo_postal : 'No registrado'}</li>
								</ul>
							</Col>
						</Row>
						<div>
							<Button className="me-2 mb-2" onClick={() => handleShow()}>Editar</Button>
						</div>
					</Card><br/>
					
					<Card>
						<Card.Body className="text-start">
							<div><h3>Historial</h3></div>
							<div className="profile-scrollable">
							<ListGroup>
								{ historial  ? historial && historial.map((data,index) => (
								<ListGroup.Item key={index} onClick={()=>handleDetalleClick(data)} className="seleccion">
									<Row>
										<Col sm={12} md={6}>
											<div>
											<strong> {data.tratamiento}</strong> 
											</div>
											<div>
											{moment(data.fecha).format("DD-MM-YYYY")}{' '}{data.hora}
											</div>
											<div>
											<strong>Estado de pago:</strong> {data.estado_pago === 'Pendiente' && <span style={{color:'red'}}>{data.estado_pago}</span>}
											{data.estado_pago === 'Pago parcial' && <span style={{color:'#ffba57'}}>{data.estado_pago}</span>}
											{data.estado_pago === 'Pagado' && <span style={{color:'#0acf97'}}>{data.estado_pago}</span>}
											</div>
										</Col>
										<Col className="text-start"  sm={12} md={6}>
											<div>
											<strong>Importe:</strong> {Number(data.precio) > 0 ? Number(data.precio).toLocaleString("es-AR", {
																				style: "currency",
																				currency: "ARS",
																				minimumFractionDigits: 2,
																			})
																			: "$ 0,00"}
											</div>											
											
											<div>
												<strong>Prestador:</strong> {data.p_nombre+', '+ data.p_apellido}
											</div>											
										</Col>
									</Row>
									<Row>
									<div>
												 {data.observaciones && <><strong>Observaciónes:</strong><br/>{data.observaciones}</>}
											</div>
									</Row>

								</ListGroup.Item>
								
								))
								:
								<div>Sin registros</div>
							}
							</ListGroup>
							</div>
						</Card.Body>
					</Card>


				</Col>
				<Col sm={12} md={6}>
					
				</Col>
			</Row>
			<Modal show={show} size="lg" onHide={handleClose}>
				<Modal.Header closeButton>				
				</Modal.Header>
				<Modal.Body><CustomerUpdate item={cliente} actualizaInfo={actualizaInfo}/></Modal.Body>
			</Modal>
			<Modal show={show1} fullscreen={true} onHide={handleClose1}>
				<Modal.Header closeButton>				
				</Modal.Header>
				<Modal.Body><DetalleVenta  datoSeleccionado={datoSeleccionado}/></Modal.Body>
			</Modal>			
		   </Container>
};

export default HistorialCliente;
