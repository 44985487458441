import React, { useState, useEffect, createContext, useContext } from "react";
import { dataHook } from "../Hooks/HooksPrivate";

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user")) || null;
  const [usuarios,setUsuarios]=useState([ ])
  const [locales, setLocales] = useState([]);
  const [prestadores, setPrestadores] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [categoria, setCategorias] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [estadoTurno, setEstadoTurno] = useState([]);
  const [estadoPago, setEstadoPago] = useState([]);
  const [localesHorarios, setLocalesHorarios] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [integraciones,setIntegraciones]=useState([]);


 const getDataAll=()=>{
	if(user){
		let a = {id: user.id_business_unit};
	dataHook( a )
	  .then((res) => {
		let data = res.data;
		if (Number(data.success) === 1) {
		  setUsuarios(data.usuarios);
		  setLocales(data.locales);
		  setPrestadores(data.prestadores);
		  setClientes(data.clientes);
		  setCategorias(data.categorias);
		  setServicios(data.servicios);
		  setTurnos(data.turnos);
		  setEstadoTurno(data.estadoturno);
		  setEstadoPago(data.estadopago);
		  setLocalesHorarios(data.localeshorarios);
		  setPagos(data.pagos);
		  setIntegraciones(data.integraciones);
		}
		
	  })
	  .catch(function (error) {
		console.error('Error al cargar datos:', error);
		if (error.response) {		
		  window.alert('Ha ocurrido un error en el servidor. Por favor, inténtalo de nuevo más tarde.');
		} else if (error.request) {		
		
		  window.alert('No se pudo establecer conexión con el servidor. Por favor, verifica tu conexión a internet e inténtalo de nuevo.');
		} else {		
		  window.alert('Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.');
		}		
		localStorage.clear();		
	  });
	}
 }

  useEffect(() => {
	if(user){
		let a = {id: user.id_business_unit};
	dataHook( a )
	  .then((res) => {
		let data = res.data;
		if (Number(data.success) === 1) {
		  setUsuarios(data.usuarios);
		  setLocales(data.locales);
		  setPrestadores(data.prestadores);
		  setClientes(data.clientes);
		  setCategorias(data.categorias);
		  setServicios(data.servicios);
		  setTurnos(data.turnos);
		  setEstadoTurno(data.estadoturno);
		  setEstadoPago(data.estadopago);
		  setLocalesHorarios(data.localeshorarios);
		  setPagos(data.pagos);
		  setIntegraciones(data.integraciones);
		}
		
	  })
	  .catch(function (error) {
		console.error('Error al cargar datos:', error);
		if (error.response) {		
		  window.alert('Ha ocurrido un error en el servidor. Por favor, inténtalo de nuevo más tarde.');
		} else if (error.request) {		
		
		  window.alert('No se pudo establecer conexión con el servidor. Por favor, verifica tu conexión a internet e inténtalo de nuevo.');
		} else {		
		  window.alert('Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.');
		}		
		localStorage.clear();		
	  });
	}
  }, []);
  

  return (
    <DataContext.Provider
      value={{
		usuarios,
		setUsuarios,
        locales,
        setLocales,
        prestadores,
        setPrestadores,
        clientes,
        setClientes,
        categoria,
        setCategorias,
        servicios,
        setServicios,
        turnos,
        setTurnos,
        estadoTurno,
        setEstadoTurno,
        estadoPago,
        setEstadoPago,
		localesHorarios,
		setLocalesHorarios,
        user,
		pagos,
		integraciones,
		getDataAll,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

const useData = () => useContext(DataContext);

export { DataProvider, useData };
