import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import * as BsIcons from "react-icons/bs";
import { ListGroup } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { NavLink, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import Update from "./Update/Update";
import Imagen from "./Imagen";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TabContent from 'react-bootstrap/TabContent'
import axios from "axios";
import {apiConfig} from "../../services/apiConfig";

const Perfil = () => {
  let { id } = useParams();
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(id), "clave_secreta");
  const info = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  const [data, setData] = useState(info);
  const [historial, setHistorial] = useState("");
  const [show, setShow] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [rolList, setRolList] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const actualizaInfo = (e) => {
    setData(e);
    setShow(false);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePassword1 = () => {
    if (passwordType === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };


  useEffect(() => {
    axios.get(apiConfig + "profile/rol/listRol.php").then((getData) => {
      setRolList(getData.data);
    });
  }, []);
  function getData(i){
		setData(i); 
}
const [actualizado, setActualizado] = useState(false)
   
const pass = () => {
	if (password.length >0 && password === password1) {  
		let userInfo = {
            userId: info.id,
            password: password,
            password1:password1
        }; 
		console.log(userInfo)
		axios.post(apiConfig+`auth/changePassword.php`, userInfo)
		.then((res) => {
		let {data} = res;                  
		if(data.success && data.message){              
				if(data.success === 1){ 
					setActualizado(true)
				}
		}
		})
		.catch(function (res) {
	
		});
	}	
 };
 

  return (
    <Container>
      <div className="text-start">
        <NavLink exact to={"/calendario"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#736CED" }}>
        <div className="card-body">
          <div className="text-center text-white">Información Usuario</div>
        </div>
      </div>
      <Row className="pt-3">
        <Col sm={12} md={12}>
          <Card>
            <Card.Body>
              <div className="perfil">
                <Imagen data={data} />
                <div>
                  <strong>
                    {data.name},{data.last_name}<br/>
					{rolList && rolList.filter((x)=> Number(x.id_user_rol) ===  Number(data.id_user_rol)).map((z,i)=>(<span className="text-muted text-start" key={i}>{z.nombre}</span>))}
                  </strong>
                </div>
              </div>
            </Card.Body>
          </Card>
          <br />

              <Card>
                <Card.Body>
                  <Tabs
                    defaultActiveKey="Perfil"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                  >
                    <Tab eventKey="Perfil" title="Perfil">
						<TabContent>
                      <div className="text-start">
                        <ul>
                          <li>
                            <strong>Nombre</strong>
                            <br />
                            {data.name},{data.last_name}
                          </li>
                          <li>
                            <strong>Teléfono</strong>
                            <br />
                            {data.phone ? data.phone : "No registrado"}
                          </li>
                          <li>
                            <strong>Correo electrónico</strong>
                            <br />
                            {data.email ? data.email : "No registrado"}
                          </li>
                        </ul>
                      </div>
					  </TabContent>
                    </Tab>
                    <Tab eventKey="Editar" title="Editar">
						<TabContent>
							<Row>
							<div className="col-md-6 col-sm-12 text-start">
								<h4>Editar perfil</h4> <br/>
								<Update data={data} getData={getData}/>
							</div>							
							<div className="col-md-6 col-sm-12 text-start">
								<h4>Cambiar contraseña</h4> <br/>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                        className="mb-2"
                      >
                        <FloatingLabel
                          controlId="floatingTotal"
                          label="Contraseña"
                          className="col-10"
                        >
                          <Form.Control
                            type={passwordType}
                            name="password"
                            placeholder=""
                            onChange={(e) => setPassword(e.target.value)}
                            minLength={6}
                            maxLength={6}
                          />
                        </FloatingLabel>
                        <div onClick={togglePassword}>
                          {passwordType === "password" ? (
                            <small>Mostrar</small>
                          ) : (
                            <small>Ocultar</small>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                        className="mb-3 "
                      >

                        <FloatingLabel
                          controlId="floatingTotal1"
                          label="Contraseña"
                          className="col-10"
                        >
                          <Form.Control
                            type={passwordType1}
                            name="password"
                            placeholder=""
                            onChange={(e) => setPassword1(e.target.value)}
                            minLength={6}
                            maxLength={6}
                          />
                        </FloatingLabel>
                        <div onClick={togglePassword1}>
                          {passwordType1 === "password" ? (
                            <small>Mostrar</small>
                          ) : (
                            <small>Ocultar</small>
                          )}
                        </div>
                      </div>
                      {password !== password1 ? 
                        <p>La contraseña no coinciden</p>
						:
						<p> </p>
					
					}
					{actualizado && <p className="text-success">Actualizado</p>}
                      <div className=" text-start">
                        <button
                          className="btn app-button  text-white shadow"
                          onClick={pass}
                        >
                          Cambiar
                        </button>
                      </div>
					  </div>
					  </Row>
					  </TabContent>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
   
          <br />
          <Card>
            <Card.Body className="text-start">
              <div>
                <h3>Historial</h3>
              </div>
              <div className="profile-scrollable">
                <ListGroup>
                  {historial ? (
                    historial.map((data, index) => (
                      <ListGroup.Item key={index}>
                        <Row>
                          <Col sm={12} md={6}>
                            <div>
                              <strong> {data.tratamiento}</strong>
                            </div>
                            <div>
                              {moment(data.fecha).format("DD-MM-YYYY")}{" "}
                              {data.hora}
                            </div>
                          </Col>
                          <Col className="text-start" sm={12} md={6}>
                            <div>
                              <strong>Importe:</strong>{" "}
                              {Number(data.precio) > 0
                                ? Number(data.precio).toLocaleString("es-AR", {
                                    style: "currency",
                                    currency: "ARS",
                                    minimumFractionDigits: 2,
                                  })
                                : "$ 0,00"}
                            </div>
                            <div>
                              <strong>Observaciónes:</strong>
                              <br /> {data.observaciones}
                            </div>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))
                  ) : (
                    <div>Sin registros</div>
                  )}
                </ListGroup>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6}></Col>
      </Row>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Update data={data} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Perfil;
