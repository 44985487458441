import axios from "axios";
import { apiService } from "../services/apiConfig";

const api = apiService;
const token ='46f76b0d-2ffa-4e3e-a3fe-6359ba465248-lsisjanjk243h2k';

const headersPrivate = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`, // Asegúrate de que "token" sea un valor válido
  };


// hook Private
export const dataHook = (data) => 
    axios.post(`${api}auth/cronogram.php`, data, {
      headers: headersPrivate
    });

  
export const listaComercioHook = (data) =>
  axios.get(`${api}business/list/?id=${data}`, headersPrivate);

export const agregarComercioHook = (data) =>
  axios.post(`${api}business/add/index.php`, data, headersPrivate);

  export const editarComercioHook = (data) =>
  axios.post(`${api}business/put/editar.php`, data, headersPrivate);

export const borrarComercioHook = (data) =>
  axios.get(`${api}business/delete/delete.php?id=${data}`, headersPrivate);

export const desactivarComercioHook = (data) =>
  axios.get(`${api}business/delete/index.php?id=${data}`, headersPrivate);

export const activarComercioHook = (data) =>
  axios.get(`${api}business/delete/index.php?id=`, data, headersPrivate);

  export const desactivarComercioDiaHook = (data) =>
  axios.get(`${api}business/day/desactive.php?id=${data}`, headersPrivate);

export const activarComercioDiaHook = (data) =>
  axios.get(`${api}business/day/active.php?id=`, data, headersPrivate);

// usuarios

export const listaRolesHook = () =>
  axios.get(`${api}profile/rol/listRol.php`, headersPrivate);

export const listaUsuarioHook = (data) =>
  axios.get(`${api}profile/list/user.php?id=${data}`, headersPrivate);

export const editaUsuarioContasenaHook = (data) =>
  axios.post(`${api}auth/changePassword.php`, data, headersPrivate);

export const agregarUsuarioHook = (data) =>
  axios.post(`${api}profile/create/register.php`, data, headersPrivate);

export const agregarUsuarioImagenHook = (data) =>
  axios.post(`${api}profile/create/image.php`, data, headersPrivate);

export const agregarRolUsuarioHook = (data) =>
  axios.post(`${api}profile/create/registerRol.php`, data, headersPrivate);

export const editarUsuarioHook = (data) =>
  axios.post(`${api}profile/update/update.php`, data, headersPrivate);

export const borrarUsuarioHook = (data) =>
  axios.get(`${api}profile/delete/delete.php?id=${data}`, headersPrivate);

export const desactivarUsuarioHook = (data) =>
  axios.get(`${api}profile/delete/index.php?id=${data}`, headersPrivate);

export const activarUsuarioaHook = (data) =>
  axios.get(`${api}profile/delete/active.php?id=`, data, headersPrivate);


// mensajeria

export const listaMensajesHook = (local, desde, hasta, estado) =>
  axios.get(
    `${api}mensajeria/list/`,
    {
      params: {
        id: local,
        desde: desde,
        hasta: hasta,
        id_estado: estado,
      },
    },
    headersPrivate
  );

export const enviarMensajesHook = (telefono, mensaje) =>
  axios.get(
    `${api}mensajeria/add/?numero=${telefono}&token=valentina&mensaje=${encodeURIComponent(
      mensaje
    )}/`,
    headersPrivate
  );

// Prestadores

export const listaPrestadoresHook = (data) =>
  axios.get(`${api}prestadores/list/agenda.php?id=${data}`, headersPrivate);

export const listaPrestadorHook = (data) =>
  axios.get(`${api}prestadores/list/?id=${data}`, headersPrivate);

export const borrarPrestadorHook = (data) =>
  axios.get(`${api}prestadores/delete/delete.php?id=${data}`, headersPrivate);

export const desactivarPrestadorHook = (data) =>
  axios.get(`${api}prestadores/delete/index.php?id=${data}`, headersPrivate);

export const activarPrestadorHook = (data) =>
  axios.get(`${api}prestadores/delete/active.php?id=`, data, headersPrivate);

export const agregarPrestadorHook = (data) =>
  axios.post(`${api}prestadores/create/register.php`, data, headersPrivate);

export const editarPrestadorHook = (data) =>
  axios.post(`${api}prestadores/update/update.php`, data, headersPrivate);

export const agregarPrestadorImagenHook = (data) =>
  axios.post(`${api}prestadores/create/image.php`, data, headersPrivate);

export const editarPrestadorImageHook = (data) =>
  axios.post(`${api}prestadores/update/image.php`, data, headersPrivate);

export const editaContasenaHook = (data) =>
  axios.post(`${api}prestadores/list/changePassword.php`, data, headersPrivate);

// Medios de Pago

export const listaEstadosPagoHook = (data) =>
  axios.get(`${api}turnos/estados/estadoPago.php?id=${data}`, headersPrivate);

export const listaPagoHook = (data) =>
  axios.get(`${api}pay/list/?id=${data}`, headersPrivate);

  export const listaPagoClienteHook = (data) =>
  axios.get(`${api}pay/list/pay.php?id=${data}`, headersPrivate);

export const agregarPagoHook = (data) =>
  axios.post(`${api}pay/add/add.php`, data, headersPrivate);

export const listaIntegracionPagoHook = (data) =>
  axios.get(`${api}pay/integracionkey.php?id=${data}`, headersPrivate);

export const integracionPagoHook = (data) =>
  axios.post(`${api}pay/integracion.php`, data, headersPrivate);

// categoria de servicios

export const listaCategoriaHook = (data) =>
  axios.get(
    `${api}category/list/categoriasActivas.php?id=${data}`,
    headersPrivate
  );

export const agregarCategoriaHook = (data) =>
  axios.post(`${api}category/add/index.php`, data, headersPrivate);

export const editarCategoriaHook = (data) =>
  axios.post(`${api}category/put/editar.php`, data, headersPrivate);

export const borrarCategoriaHook = (data) =>
  axios.get(`${api}category/delete/delete.php?id=${data}`, headersPrivate);

export const desactivarCategoriaHook = (data) =>
  axios.get(`${api}category/delete/index.php?id=${data}`, headersPrivate);

export const activarCategoriaHook = (data) =>
  axios.get(`${api}category/delete/active.php?id=`, data, headersPrivate);

// Servicios

export const listaServiciosHook = (data) =>
  axios.get(
    `${api}service/list/serviciosActivos.php?id=${data}`,
    headersPrivate
  );

export const listaTodosServiciosHook = (data) =>
  axios.get(`${api}service/list/all.php?id=${data}`, headersPrivate);

export const listaServiciosCategoriaHook = (data, b) =>
  axios.get(
    `${api}service/list/all.php?id=${data}&categoria=${b}`,
    headersPrivate
  );

export const agregarServiciosHook = (data) =>
  axios.post(`${api}service/add/index.php`, data, headersPrivate);

export const editarServiciosHook = (data) =>
  axios.post(`${api}service/put/editar.php`, data, headersPrivate);

export const borrarServiciosHook = (data) =>
  axios.get(`${api}service/delete/delete.php?id=${data}`, headersPrivate);

export const desactivaServiciosHook = (data) =>
  axios.get(`${api}service/delete/?id=${data}`, headersPrivate);

export const activarServiciosHook = (data) =>
  axios.get(`${api}service/delete/active.php?id=${data}`, headersPrivate);

// Turnos 
export const listaTurnosHook = (data) =>
  axios.get(`${api}turnos/list/?id=${data}`, headersPrivate);

  export const listaClienteTurnosHook = (data) =>
  axios.get(`${api}turnos/list/individual.php?id=${data}`, headersPrivate);

export const listaEstadosTurnosHook = (data) =>
  axios.get(`${api}turnos/estados/estadoTurno.php?id=${data}`, headersPrivate);

export const agregarTurnoHook = (data) =>
  axios.post(`${api}turnos/add/add.php`, data, headersPrivate);

export const editarTurnoHook = (data) =>
  axios.post(`${api}turnos/put/editar.php`, data, headersPrivate);

export const borrarTurnoHook = (data) =>
  axios.post(`${api}turnos/delete/delete.php`, data, headersPrivate);

// Clientes

export const listaClientesHook = (data) =>
  axios.get(`${api}customer/list/?id=${data}`, headersPrivate);

export const agregarClienteHook = (data) =>
  axios.post(`${api}customer/add/index.php`, data, headersPrivate);

export const editarClienteHook = (data) =>
  axios.post(`${api}customer/update/index.php`, data, headersPrivate);

//ver api borrar
export const borrarClienteHook = (data) =>
  axios.get(`${api}customer/delete/delete.php?id=${data}`, headersPrivate);

export const desactivarClienteaHook = (data) =>
  axios.get(`${api}customer/delete/delete.php?id=${data}`, headersPrivate);

export const activarClienteHook = (data) =>
  axios.get(`${api}customer/delete/active.php?id=${data}`, headersPrivate);

// Notificaciones

// Licencia - suscripcion
// Ficha paciente #id cliente
export const fichaPacienteHook = (data) =>
  axios.get(`${api}customer/paciente/?id=${data}`, headersPrivate);