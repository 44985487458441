import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import NotFoundPage from "../components/NotFoundPage/NotFoundPage";
import Login from "../auth/Login/Login";
import Register from "../auth/Register/Register";
import Restore from "../auth/Restore/Restore";
import Home from "../pages/home/Home";
import { Config } from "../pages/config";
import UsersProfile from "../components/profile/usersProfile";
import Business from "../components/Business/Business";
import {CustomerList} from "../pages/customer/CustomerList";
import { CustomerAdd } from "../pages/customer/CustomerAdd";
import { CustomerUpdate } from "../pages/customer/CustomerUpdate";
import { CustomerImport} from "../pages/customer/CustomerImport";
import BoxList from "../pages/box/BoxList/BoxList";
import Dashboard from "../pages/Dashboard/Dashboard"
import BoxUpdate from "../pages/box/BoxUpdate/BoxUpdate";
import { Category } from "../pages/category/Category";
import { Servicios } from "../pages/category/Servicios";
import Agenda from "../pages/Agenda/Agenda";
import CalendarComponent from "../pages/Agenda/CalendarComponent";
import Prestador from "../pages/Prestador/Prestador";
import PerfilPrestador from "../pages/Prestador/PerfilPrestador";
import Medios from "../pages/Medios/Medios";
import Website from "../pages/Website/Website";
import Mensajeria from "../pages/Mensajeria/Mensajeria";
import Locales from "../pages/Locales/Locales";
import HistorialCliente from "../pages/customer/HistorialCliente/HistorialCliente";
import Terminosycondiciones from "../pages/Terminos/Terminosycondiciones";
import Perfil from "../pages/Perfil/Perfil";
import Report from "../pages/Report/Report";
import WebUpdate from "../pages/Website/WebUpdate";
import WebsiteAgenda from "../pages/Website/WebsiteAgenda";
import PayLicencia from "../components/PayLicencia/PayLicencia"
import Paciente from "../pages/Paciente/Paciente";
import Suscripcion from "../pages/Suscripcion/Suscripcion";




export default function AppRouter() {
  return (
    <Router>      
      <Switch>
        <PublicRoute exact path="/" component={Website} />   
        <PublicRoute exact path="/login" component={Login} />  
        <PublicRoute exact path="/register" component={Register} />
        <PublicRoute exact path="/recuperar" component={Restore} />
        <PublicRoute exact path="/licencia" component={PayLicencia} />        
        <PrivateRoute  path="/website/agendar/:id" component={WebsiteAgenda}/>
        <PrivateRoute exact path="/Home" component={Home} />
        <PrivateRoute exact path="/calendario" component={CalendarComponent}/>
        <PrivateRoute exact path="/paciente/:id" component={Paciente}/>
        <PrivateRoute exact path="/config/webUpdate" component={WebUpdate}/>
        <PrivateRoute exact path="/config/locales" component={Locales}/>
        <PrivateRoute exact path="/config/prestadores" component={Prestador}/>
        <PrivateRoute exact path="/config/mensajeria" component={Mensajeria}/>
        <PrivateRoute exact path="/config/medios" component={Medios}/>
        <PrivateRoute exact path="/agenda" component={Agenda} />        
        <PrivateRoute exact path="/Dashboard" component={Dashboard} />
        <PrivateRoute exact path="/config" component={Config} />
        <PrivateRoute exact path="/config/profile" component={UsersProfile} />
        <PrivateRoute exact path="/profile/update/:id" component={Perfil}/>
        <PrivateRoute exact path="/profile/prestadores/update/:id" component={PerfilPrestador}/>
        <PrivateRoute exact path="/config/business" component={Business} />        
        <PrivateRoute exact path="/config/customer" component={CustomerList} />
        <PrivateRoute exact path="/config/customerAdd" component={CustomerAdd} />
        <PrivateRoute exact path="/config/customerUpdate" component={CustomerUpdate} />
        <PrivateRoute exact path="/config/customerImport" component={CustomerImport} />
        <PrivateRoute exact path="/customer/:id" component={HistorialCliente} />
        <PrivateRoute exact path="/config/box" component={BoxList} />
        <PrivateRoute exact path="/config/boxUpdate" component={BoxUpdate} />
        <PrivateRoute exact path="/config/categoria" component={Category} />
        <PrivateRoute exact path="/config/servicios/:id" component={Servicios} />        
        <PrivateRoute exact path="/reporte" component={Report} />
        <PrivateRoute exact path="/terminos" component={Terminosycondiciones} />
        <PrivateRoute exact path="/mensajeria" component={Mensajeria} />
        <PrivateRoute exact path="/suscripcion" component={Suscripcion} />
        <Route path="/404" component={NotFoundPage} />
        <Route path="*">
          <Redirect to="/404" /> 
        </Route>
      </Switch>
    </Router>
  );
}


