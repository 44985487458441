import React, { useEffect, useState } from "react";
import { Navbar, Container, Row, Col, Accordion, Spinner } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import {apiConfig} from "../../services/apiConfig";
import axios from "axios";
import * as BsIcons from "react-icons/bs";

import "./Website.css";

function Website(){
  let {id}= useParams();
  
  const [data, setData] = useState(null);
  const [category, setCategory] = useState([]);
  const [service, setService] = useState([]);
  const [loading,setLoading] = useState(false)


  function getData() {
    axios
      .get(apiConfig + "web_cliente/list/?id=" + Number(1))
      .then((getData) => {
        const res = getData.data;

        if (res.length > 0) {
          setData({
            nombre: res[0].nombre,
            logo: res[0].logo,
            background: res[0].background,
            ubicacion: res[0].ubicacion,
            telefono: res[0].telefono,
            email: res[0].email,
            descripcion: res[0].descripcion,
            instagram: res[0].instagram,
            facebook: res[0].facebook,
            latitud: res[0].latitud,
            longitud: res[0].longitud,
            id_business_unit: res[0].id_business_unit,
            dias: JSON.parse(res[0].dias),
          });
         setLoading(true)
        }
      });
  }

  useEffect(() => {
    getData();
  }, [id]);

  function getCategory() {
    axios
      .get(apiConfig + "category/list/?id=" + Number(data.id_business_unit))
      .then((getData) => {
        setCategory(getData.data);
      });
  }
  function getService() {
    axios
      .get(
        apiConfig + "service/list/all.php?id=" + Number(data.id_business_unit)
      )
      .then((getData) => {
        setService(getData.data);
      });
  }

  useEffect(() => {
    if (data) {
      getCategory();
      getService();
    }
  }, [data]);

  const MapComponent = () => {
    return (
      <iframe
        src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d6699.660029286504!2d${
          data && data.longitud
        }!3d${
          data && data.latitud
        }!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2sar!4v1689773410011!5m2!1ses!2sar`}
        style={{ borderRadius: "10px", width: "100%" }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    );
  };
  function getMinutesText(value) {
    switch (value) {
      case 78:
        return 30;
      case 117:
        return 45;
      case 156:
        return 60;
      case 195:
        return 75;
      case 234:
        return 90;
      case 273:
        return 105;
      case 312:
        return 120;
      case 351:
        return 135;
      case 390:
        return 150;
      case 429:
        return 165;
      case 468:
        return 180;
      default:
        return "";
    }
  }

  return (
    <>
    {loading ?
      <div style={{background: 'rgb(245 245 245 / 36%)'}}>
        <Navbar className=" navbar-dark">
          <Container>
            <div className="btnCenter">
              <div className="d-none d-sm-none d-md-block text-end">
                <a
                  href={`https://api.whatsapp.com/send/?phone=%2B549${
                    data && data.telefono
                  }&text=Buen+día+quiero+información+sobre+cronogram&app_absent=0`}
                  target="_blank"
                >
                  <img
                    src="https://cronogram.com.ar/assets/logo-wasap.png"
                    className="shadow"
                    style={{ borderRadius: "50px 50px" }}
                    width={80}
                    height={80}
                  />
                </a>
              </div>
              <div className="d-sm-block d-md-none text-end">
                <a
                  href={`https://api.whatsapp.com/send/?phone=%2B549${
                    data && data.telefono
                  }&text=Buen+día+quiero+información+sobre+cronogram&app_absent=0`}
                  target="_blank"
                >
                  <img
                    src="https://cronogram.com.ar/assets/logo-wasap.png"
                    className="shadow"
                    style={{ borderRadius: "50px 50px" }}
                    width={55}
                    height={55}
                  />
                </a>
              </div>
            </div>
            <Navbar.Brand>
              <img
                className="navbar-logo-img"
                src={
                  data
                    ? data.logo
                    : apiConfig + "business/image/businessGeneric.svg"
                }
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <NavLink exact to="/login">Ingresar</NavLink>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div
          style={{
            backgroundImage: `url(${data && data.background})`,
            backgroundSize: "cover",
            backgroundPosition: "center",            
          }}
        >
          <Container className="header-web ">
            <div className="header-contact">
              <div className="header-content-icon">
                <div className="header-icon text-white">
                  <BsIcons.BsFillTelephoneFill />
                </div>
                <div className="header-text">
                  <small>{data && data.telefono}</small>
                </div>
              </div>
              <div className="header-content-icon ">
                <div className="header-icon text-white">
                  <BsIcons.BsGeoAltFill />
                </div>
                <div className="header-text">
                  <small>{data && data.ubicacion}</small>
                </div>
              </div>
            </div>
          </Container>
          </div>
        <div className=" container">
          <Row>
            <Col sm={12} md={4}>
              <br/>
              <div className="card-web text-center">
                <br />
                <h5>
                  <strong>{data && data.nombre}</strong>
                </h5>
                <br />
                <p className="text-muted">{data && data.descripcion}</p>
                <div className="social-container">
                <a href={data && data.instagram} target="_blank"><small><BsIcons.BsInstagram /> Instagram</small></a>
                  <a href={data && data.facebook} target="_blank"><small><BsIcons.BsFacebook /> Facebook</small></a>
                </div>
              </div>
              <br />
              <div className="card-web">
                <MapComponent />
                <br />
                <div
                  className="text-start"
                  style={{ padding: "1rem 1.2rem 0.5rem 1.3rem" }}
                >
                  Ubicación
                </div>
                <hr />
                <Accordion alwaysOpen>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Horario</Accordion.Header>
                    <Accordion.Body>
                      {data &&
                        data.dias.map((a, i) => (
                          <div className="row" key={i}>
                            <div className="col text-start">{(a.dia_semana).slice(0, 3)}</div>
                            <div className="col">
                              {a.horario_apertura.length > 0
                                ? a.horario_apertura
                                : "cerrado"}
                            </div>
                            <div className="col">
                              {a.horario_cierre.length > 0
                                ? a.horario_cierre
                                : "cerrado"}
                            </div>
                          </div>
                        ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
            <Col sm={12} md={8} style={{ color: "black" }}>
              <Container>
                <div className="pt-3 mb-3 text-dark display-6">
                  Servicios por categoria
                </div>
                <div className="lista-horizontal text-dark scroll-horizontal ">
                  {category &&
                    category.map((d, i) => (
                      <div className="elemento-lista" key={i}>
                        {d.nombre}
                      </div>
                    ))}
                </div>
                {category &&
                  category.map((d, i) => (
                    <div className="text-start pt-5" key={i}>
                      <h5>
                        <strong>{d.nombre}</strong>
                      </h5>
                      <br />
                      {service &&
                        service
                          .filter((x) => Number(x.id_categoria) === Number(d.id))
                          .map((datos, index) => (
                     
                            <div className="card-web mb-3" key={index}>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-1 col-md-1"></div>
                                  <div className="col-8 col-md-9">
                                    <strong>{datos.nombre}</strong>
                                    <br />
                                    <small className="text-dark">
                                      {datos.descripcion}
                                    </small>
                                  </div>
                                  <div className="col-3 col-md-2 text-end">
                                    <small className="text-muted">
                                      <BsIcons.BsFillClockFill />{" "}
                                      {getMinutesText(Number(datos.duracion))}{" "}
                                      min
                                    </small>
                      
                                  </div>
                                </div>
                              </div>
                            </div>
                         
                          ))}
                    </div>
                  ))}
              </Container>
            </Col>
          </Row>
        </div><br/>
        <div className="text-white bg-dark " style={{padding: '20px', minHeight: '200px', position: 'relative'}}>
            <Container>
              <Row>
                <Col sm={6} md={3}>
                  <div style={{width:'50px',height:'50px'}}>
                    <img
                      className="img-fluid"
                      style={{borderRadius:'25px'}}
                      src={data && data.logo}
                      alt="Logo"
                    />
                  </div>
                </Col>
                <Col sm={6} md={3} className="text-start text-white">
                 Encuentranos<br/><br/>
                  <small><BsIcons.BsGeoAltFill />{' '}{data && data.ubicacion}</small><br/>
                  <small><BsIcons.BsFillTelephoneFill />{' '}{data && data.telefono}</small>
                </Col>
                <Col sm={6} md={3} className="text-start text-white">
                  Redes sociales<br/><br/>
                  <a href={data && data.instagram} target="_blank"><small className="text-white"><BsIcons.BsInstagram /> Instagram</small></a><br/>
                  <a href={data && data.facebook} target="_blank"><small className="text-white"><BsIcons.BsFacebook /> Facebook</small></a>
                </Col>
                <Col sm={6} md={3} className="text-start">
                 Información<br/><br/>
                  <a href="#"><small className="text-white">Termino y condiciónes</small></a>
                </Col>
              </Row>
            </Container>
            <hr />
            <div style={{position: 'absolute', bottom: '10px', left: '50%'}}>
              <small className="text-muted">
                Copyright © <strong>Cronogram</strong> 2023
              </small>
            </div>
          </div>
      </div>
      :
      <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100vh'}}>
          <Spinner className=" text-primary"/>
      </div>
   
      }
      </>
  );
};

export default Website;
